import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
// slices
import globalReducer from './slices/global';
import runReducer from './slices/run';
import mapReducer from './slices/map';
import farmReducer from './slices/farm';
import processorReducer from './slices/processor';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const combinedReducer = combineReducers({
  global: globalReducer,
  run: runReducer,
  map: mapReducer,
  farm: farmReducer,
  processor: processorReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === 'global/logout') {
    // check for action type
    state = undefined;
  }
  return combinedReducer(state, action);
};

export { rootPersistConfig, rootReducer };
