import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Input(theme: Theme) {
  return {
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            '& svg': { color: theme.palette.text.disabled },
          },
        },
        input: {
          '&::placeholder': {
            opacity: 1,
            color: theme.palette.text.disabled,
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          '&:before': {
            borderBottomColor: theme.palette.grey[500_56],
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.grey[500_12],
          '&:hover': {
            backgroundColor: theme.palette.grey[500_16],
          },
          '&.Mui-focused': {
            backgroundColor: theme.palette.action.focus,
          },
          '&.Mui-disabled': {
            backgroundColor: theme.palette.action.disabledBackground,
          },
        },
        underline: {
          '&:before': {
            borderBottomColor: theme.palette.grey[500_56],
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.MuiOutlinedInput-root': {
            opacity: 0.7,
            '&.Mui-focused': {
              opacity: 1,
              fieldset: { borderColor: theme.palette.common.dark },
            },
          },
          input: {
            ...theme.typography.body1,
            '&::placeholder': {
              ...theme.typography.placeholder,
              textAlign: 'center',
              color: theme.palette.common.dark,
              opacity: 0.5,
            },
            '~ fieldset': {
              borderWidth: '1px !important',
              borderRadius: 40,
            },
          },
          legend: {
            marginLeft: 15,
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.common.dark,
          },
          '&.Mui-disabled': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.common.dark,
            },
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          '&.MuiInputLabel-shrink': {
            transform: `translate(${14}px, ${-10}px) scale(1)`,
            fontSize: 12,
            color: theme.palette.common.dark,
            marginLeft: 15,
            opacity: 0.7,
            '&.Mui-focused': {
              color: theme.palette.common.dark,
              opacity: 1,
              transition: 'all 200ms ease-out',
            },
          },
        },
      },
    },
  };
}
