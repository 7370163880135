import { sub } from 'date-fns';
//
import { role } from './role';
import { email } from './email';
import { boolean } from './boolean';
import { company } from './company';
import { phoneNumber } from './phoneNumber';
import { fullAddress, country } from './address';
import { firstName, lastName, fullName } from './name';
import { title, sentence, description } from './text';
import { price, rating, age, percent } from './number';
import { user, lastActive, dateRegistered, eventsAssigned, status } from './user';
import { order, customer, event, dateOfPurchase, items, totalCost } from './order';
import { eventName,statusEvent, author, location, startDate, endDate, attendees, waitlist } from './event';
// ----------------------------------------------------------------------

const _mock = {
  id: (index: number) => `e99f09a7-dd88-49d5-b1c8-1daf80c2d7b${index + 1}`,
  user: (index: number) => user[index],
  email: (index: number) => email[index],
  lastActive: (index: number) => lastActive[index],
  dateRegistered: (index: number) => dateRegistered[index],
  eventsAssigned: (index: number) => eventsAssigned[index],
  status: (index: number) => status[index],
  phoneNumber: (index: number) => phoneNumber[index],
  time: (index: number) => sub(new Date(), { days: index, hours: index }),
  boolean: (index: number) => boolean[index],
  role: (index: number) => role[index],
  company: (index: number) => company[index],
  address: {
    fullAddress: (index: number) => fullAddress[index],
    country: (index: number) => country[index],
  },
  name: {
    firstName: (index: number) => firstName[index],
    lastName: (index: number) => lastName[index],
    fullName: (index: number) => fullName[index],
  },
  text: {
    title: (index: number) => title[index],
    sentence: (index: number) => sentence[index],
    description: (index: number) => description[index],
  },
  number: {
    percent: (index: number) => percent[index],
    rating: (index: number) => rating[index],
    age: (index: number) => age[index],
    price: (index: number) => price[index],
  },
  image: {
    cover: (index: number) =>
      `https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_${index + 1}.jpg`,
    feed: (index: number) =>
      `https://minimal-assets-api-dev.vercel.app/assets/images/feeds/feed_${index + 1}.jpg`,
    product: (index: number) =>
      `https://minimal-assets-api-dev.vercel.app/assets/images/products/product_${index + 1}.jpg`,
    avatar: (index: number) =>
      `https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_${index + 1}.jpg`,
  },
  order: (index: number) => order[index],
  customer: (index: number) => customer[index],
  event: (index: number) => event[index],
  dateOfPurchase: (index: number) => dateOfPurchase[index],
  items: (index: number) => items[index],
  totalCost: (index: number) => totalCost[index],

  eventName:(index: number) => eventName[index],
  statusEvent: (index: number) => statusEvent[index],
  author: (index: number) => author[index],
  location: (index: number) => location[index],
  startDate: (index: number) => startDate[index],
  endDate: (index: number) => endDate[index],
  attendees: (index: number) => attendees[index],
  waitlist: (index: number) => waitlist[index],
};

export default _mock;
