import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Theme } from '@mui/material/styles';
import { Box, BoxProps, SxProps } from '@mui/material';
// components
import Image from 'components/Image';
// hooks
import { useIsTransportUser } from 'hooks/useCheckPermission';
// routes
import { PATH_PAGE } from 'routes/paths';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  sx?: SxProps<Theme>;
  disabledLink?: boolean;
}

export default function Logo({ disabledLink = false, sx }: Props) {
  const isTransportUser = useIsTransportUser();

  const redirectPermission = isTransportUser ? PATH_PAGE.runManagement : PATH_PAGE.dashboard;

  const logo = (
    <Box>
      <Image src={`${process.env.PUBLIC_URL}/assets/logo-mls.png`} sx={{ ...sx }} />
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to={redirectPermission}>{logo}</RouterLink>;
}
