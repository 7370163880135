import React, { memo } from 'react';
// @mui
import { Box, BoxProps } from '@mui/material';

type IconProps = {
  color?: string;
  width?: number;
  height?: number;
} & BoxProps;

function PlusIcon({ color = '', width = 10, height = 10, ...other }: IconProps) {
  return (
    <Box {...other}>
      <svg width={width} height={height} viewBox="0 0 8 8" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M3.11009 7.44815V0.40838H4.88991V7.44815H3.11009ZM0.480114 4.81818V3.03835H7.51989V4.81818H0.480114Z"
          fill="inherit"
        />
      </svg>
    </Box>
  );
}

export default memo(PlusIcon);
