import { format } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import AddIcon from '@mui/icons-material/Add';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Drawer,
  FormControlLabel,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Stack,
  Typography,
  styled,
} from '@mui/material';
// routes
import { PATH_PAGE } from 'routes/paths';
// hooks
import useIsMountedRef from 'hooks/useIsMountedRef';
// components
import { FormProvider, RHFCustomSelect } from 'components/hook-form';
import ScheduleDatePicker from 'sections/run/schedule/ScheduleDatePicker';
import { ScheduleListRun } from './index';
// utils
import { fDateDMY } from 'utils/formatTime';
import { getInitialDate, validateDisabledDates } from 'utils/runScheduler';
import 'utils/yup';
//services
import * as runServices from 'services/run';
//types
import { RegionType } from '_types/run';
import { useSnackbar } from 'notistack';
// redux
import { getRegionList, updateFilterRegion, updateScheduleOpen } from 'redux/slices/run';

//redux
import { getProcessorList } from 'redux/slices/processor';
import { useDispatch, useSelector } from 'redux/store';

// ----------------------------------------------------------------------

const TopStyle = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-end',
  justifyContent: 'flex-end',
  gap: '10px',
  padding: '20px 30px',
  width: '100%',
  background: '#FFFFFF',
  borderBottom: '1px solid #F1F0EC',
  minWidth: 520,
}));

const BottomStyle = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '16px',
  padding: '30px 30px 16px 30px',
  width: '100%',
  // height: '100%',
  background: '#FFFFFF',
  minWidth: 520,
}));

const FormStyle = styled('div')(() => ({
  width: '100%',
}));

const ButtonCancelStyle = styled(Button)(() => ({
  color: 'rgba(49, 49, 49, 0.7)',
  borderColor: '#FFFFFF',
  '&:hover': {
    borderColor: 'rgba(49, 49, 49, 0.7)',
    background: '#FFFFFF',
  },
}));

const TextTitleStyle = styled(Typography)(() => ({
  fontWeight: 400,
  fontSize: '20px',
  lineHeight: '23px',
  color: '#02344A',
}));

const FormControlLabelStyle = styled(FormControlLabel)(({ theme }) => ({
  alignItems: 'flex-start',
  margin: 0,
  width: '100%',
}));

const SelectFieldStyle = styled(RHFCustomSelect)(({ theme }) => ({
  '.MuiOutlinedInput-root': {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '16px',
    color: '#313131',
  },
  '.MuiSelect-select': {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 15px',
    border: '1px solid #DBDBDB',
    borderRadius: '3px',

    fontWeight: 400,
    fontSize: 14,
    lineHeight: '16px',
    color: '#313131',
  },
  '.MuiOutlinedInput-notchedOutline': {
    padding: '10px 15px',
    border: '1px solid #DBDBDB',
    borderRadius: '3px',

    fontWeight: 400,
    fontSize: 14,
    lineHeight: '16px',
    color: '#313131',
  },
}));

const ButtonScheduleRunsStyle = styled(LoadingButton)(() => ({
  background: '#EAC353',
  borderRadius: '100px',
  padding: '10px 0px',
  boxShadow: 'none',
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '19px',
  color: '#313131',
  '&:hover': {
    background: '#EAC353',
  },
}));

// ----------------------------------------------------------------------

type FormValuesProps = {
  region: string;
  items: {
    id: string;
    date: string | Date;
    fore: string;
  }[];
  afterSubmit?: string;
};

type Props = {
  open: boolean;
  setOpen: (value: boolean) => void;
};

// ----------------------------------------------------------------------

// const MIN_DATE = new Date(new Date().setHours(new Date().getHours() + 48 - 15));

export default function Schedule({ open, setOpen }: Props) {
  const isMountedRef = useIsMountedRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();
  const { regionList } = useSelector((state) => state.run);
  const { processorList } = useSelector((state) => state.processor);

  const [loading, setLoading] = useState(false);

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [scheduleDates, setScheduleDates] = useState<Date[]>([]);
  const [carrierOptions, setCarrierOptions] = useState([]);
  const [isDisableCreate, setIsDisableCreate] = useState(false);

  useEffect(() => {
    dispatch(getRegionList());
    dispatch(getProcessorList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    reset();
    if (!open) setScheduleDates([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const disabledDates = (date: Date) => validateDisabledDates(date, getInitialDate());

  const ScheduleSchema = Yup.object().shape({
    region: Yup.string().nullable().required('Processor is required'),
    items: Yup.array()
      .of(
        Yup.object().shape({
          date: Yup.date()
            .typeError('Invalid Date')
            .min(getInitialDate(), `Date cannot be earlier than ${fDateDMY(getInitialDate())}`)
            .nullable()
            .required('Date is required'),
          fore: Yup.string().nullable(),
        })
      )
      .uniqueProperty('date', 'Date must be unique'),
  });

  const defaultValues = useMemo(
    () => ({
      region: '',
      items: [],
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(ScheduleSchema),
    defaultValues,
  });

  const {
    setValue,
    reset,
    watch,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  const onSubmit = async (data: FormValuesProps) => {
    setLoading(true);
    try {
      const runSchedules: any = [];
      data?.items?.map((item: any, index) => {
        const listCarrierId: string[] = [];
        item?.carrier?.map((carrier: any) => listCarrierId.push(carrier?.id));
        runSchedules.push({
          id: index,
          deliveryDate: `${format(new Date(item?.date), 'yyyy-MM-dd')}T00:00:00.000`,
          forecastedCapacity: +item.fore,
          carrierIds: listCarrierId,
        });
      });
      const params = {
        regionId: data.region,
        runSchedules: runSchedules,
      };
      await runServices
        .createScheduleRuns(params)
        .then((response: any) => {
          if (response.data) {
            (response?.data as []).forEach((item: any) => {
              if (!item?.createdStatus) {
                enqueueSnackbar(
                  `Schedule run Date : ${format(new Date(item.date), 'dd LLL, yy')} ${
                    item?.message
                  }`,
                  {
                    variant: 'error',
                  }
                );
              } else {
                enqueueSnackbar(
                  `Create success schedule run Date : ${format(new Date(item.date), 'dd LLL, yy')}`
                );
              }
            });
          }
        })
        .catch((error) => {
          const errorResponse = error?.data.data as [];
          errorResponse?.forEach((item: any) => {
            enqueueSnackbar(
              `Schedule run Date : ${format(new Date(item?.date), 'dd LLL, yy')} ${item?.message}`,
              {
                variant: 'error',
              }
            );
          });
        });
      dispatch(updateScheduleOpen(false));
      setOpen(false);
      if (values?.region) dispatch(updateFilterRegion(values?.region));
      navigate(PATH_PAGE.runManagement, { replace: true });
    } catch (error) {
      if (isMountedRef.current) {
      }
    }
  };

  const checkCarrierOptions = (selectProcessorId: string) => {
    const processor = (processorList as []).find(
      (item: any) => item?.id === selectProcessorId
    ) as any;

    if (processor?.carriers) {
      setCarrierOptions(processor?.carriers);
      setIsDisableCreate(false);
    } else {
      setCarrierOptions([]);
      setIsDisableCreate(true);
    }
  };

  useEffect(() => {
    if (values?.region) {
      setIsCalendarOpen(true);
      checkCarrierOptions(values?.region);
      setScheduleDates([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.region]);

  useEffect(() => {
    const itemList = values?.items as [];
    let disable = false;

    itemList.map((item: any) => {
      if (!(item?.carrier && item?.carrier.length > 0)) {
        disable = true;
      } else if (+item.fore <= 0) {
        disable = true;
      }
    });

    setIsDisableCreate(disable);
  }, [values]);

  return (
    <Drawer open={open} anchor="right">
      <TopStyle>
        <ButtonCancelStyle
          variant="text"
          onClick={() => {
            setOpen(false);
            dispatch(updateScheduleOpen(false));
          }}
        >
          Cancel
        </ButtonCancelStyle>
      </TopStyle>
      <BottomStyle>
        <TextTitleStyle>Schedule runs</TextTitleStyle>
        <FormStyle>
          <FormProvider methods={methods}>
            <Stack sx={{ gap: '16px' }}>
              <FormControlLabelStyle
                control={
                  <SelectFieldStyle
                    fullWidth
                    native={false}
                    name="region"
                    input={<OutlinedInput />}
                    labelId="region-label"
                    displayEmpty
                    renderValue={
                      values?.region !== ''
                        ? undefined
                        : () => (
                            <Typography
                              sx={{
                                fontWeight: 400,
                                fontSize: '14px',
                                lineHeight: '16px',
                                color: 'rgba(49, 49, 49, 0.7)',
                              }}
                            >
                              Select processor
                            </Typography>
                          )
                    }
                  >
                    {regionList?.map((option: RegionType) => (
                      <MenuItem key={option?.id} value={option?.id}>
                        {option?.name}
                      </MenuItem>
                    ))}
                  </SelectFieldStyle>
                }
                label={
                  <InputLabel id="region-label">
                    <Typography sx={{ fontWeight: 500, fontSize: 13, color: '#313131' }}>
                      Processor *
                    </Typography>
                  </InputLabel>
                }
                labelPlacement="top"
              />

              {/* Start Calendar */}
              {open && values?.region && (
                <ScheduleDatePicker
                  open={isCalendarOpen}
                  onClose={() => setIsCalendarOpen(false)}
                  dates={scheduleDates}
                  disabledDates={disabledDates}
                  setDates={setScheduleDates}
                />
              )}
              {/* End Calendar */}

              {values?.region && (
                <>
                  {scheduleDates && scheduleDates?.length > 0 && (
                    <ScheduleListRun
                      scheduleDates={scheduleDates}
                      optionCarrier={carrierOptions}
                      minDay={getInitialDate()}
                      setDates={setScheduleDates}
                      setValue={setValue}
                    />
                  )}
                  <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={() => setIsCalendarOpen(true)}
                    sx={{ alignSelf: 'start' }}
                  >
                    Add Run
                  </Button>
                </>
              )}
            </Stack>
          </FormProvider>
        </FormStyle>
      </BottomStyle>
      {/* Start - Show error messages */}
      {/* {errors?.items && (
        <Typography variant="body1" sx={{ color: 'error.main', textAlign: 'center', mt: 2.25 }}>
          {errors?.items && 'Duplicate Date'}
        </Typography>
      )} */}
      {/* End - Show error messages */}

      {values?.region && values?.items?.length > 0 && scheduleDates && scheduleDates?.length > 0 && (
        <Stack justifyContent="flex-end" direction="row" sx={{ padding: '0px 30px 30px 30px' }}>
          <ButtonScheduleRunsStyle
            fullWidth
            loading={loading && isSubmitting}
            onClick={handleSubmit(onSubmit)}
            disabled={isDisableCreate}
          >
            Schedule runs
          </ButtonScheduleRunsStyle>
        </Stack>
      )}
    </Drawer>
  );
}
