import React, { useEffect } from 'react';
// form
import { RHFDatePicker, RHFTextField } from 'components/hook-form';
import { UseFormSetValue, useFieldArray, useFormContext } from 'react-hook-form';
// @mui
import CloseIcon from '@mui/icons-material/Close';
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  Stack,
  TextField,
  Typography,
  styled,
} from '@mui/material';
// components
import Scrollbar from 'components/Scrollbar';
// assets
import { UnionIcon } from 'assets';
// utils
import { isWeekend, startOfDay, isSameDay } from 'date-fns';
import { findIndexDate } from 'utils/date';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
// ----------------------------------------------------------------------

const InputStyle = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  padding: '10px',
  gap: '10px',

  background: '#F1F0EC',
  borderRadius: '3px',
  width: '100%',
}));

const RowStyle = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'center',
  padding: '10px',
  gap: '10px',

  background: '#F1F0EC',
  borderRadius: '3px',
  width: '100%',
}));

const DatePickerFieldStyle = styled(RHFDatePicker)(() => ({
  '.MuiOutlinedInput-root': {
    background: '#FFFFFF',
    fieldset: {
      padding: '10px 15px',
      border: '1px solid #DBDBDB',
      borderRadius: '3px',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: 16,
      color: 'rgba(49, 49, 49, 0.7)',
    },
    input: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '16px',
      color: '#313131',
      padding: '10px 15px',
      '&::placeholder': {
        textAlign: 'start',
      },
    },
  },
}));

const TextFieldStyle = styled(RHFTextField)(() => ({
  '.MuiOutlinedInput-root': {
    background: '#FFFFFF',
    fieldset: {
      padding: '10px 15px',
      border: '1px solid #DBDBDB',
      borderRadius: '3px',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: 16,
      color: 'rgba(49, 49, 49, 0.7)',
    },
    input: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '16px',
      color: '#313131',
      padding: '10px 0px 10px 15px',
      '&::placeholder': {
        textAlign: 'start',
      },
    },
  },
}));

const AutocompleteStyle = styled(Autocomplete)(() => ({
  '.MuiOutlinedInput-root': {
    background: '#FFFFFF',
    fieldset: {
      padding: '10px 15px',
      border: '1px solid #DBDBDB',
      borderRadius: '3px',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: 16,
      color: 'rgba(49, 49, 49, 0.7)',
    },
    input: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '16px',
      color: '#313131',
      padding: '10px 0px 10px 15px',
      '&::placeholder': {
        textAlign: 'start',
      },
    },
  },
}));

const FormControlLabelStyle = styled(FormControlLabel)(({ theme }) => ({
  alignItems: 'flex-start',
  margin: 0,
  width: '100%',
}));

// ----------------------------------------------------------------------

type Props = {
  scheduleDates: Date[];
  optionCarrier: any;
  setDates: React.Dispatch<React.SetStateAction<Date[]>>;
  setValue: UseFormSetValue<any>;
  minDay: Date;
};

type FieldType = {
  date: Date;
  fore: '';
};

export default function ScheduleListRun({
  scheduleDates,
  optionCarrier,
  setDates,
  setValue,
  minDay,
}: Props) {
  const { control } = useFormContext();

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'items',
  });

  const handleRemove = (index: number) => {
    const dateToRemove = (fields[index] as unknown as FieldType)?.date;
    const newScheduleDates = scheduleDates.filter((item) => !isSameDay(item, dateToRemove));
    setDates(newScheduleDates);
  };

  useEffect(() => {
    fields.forEach((field, index: number) => {
      remove(index);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (scheduleDates?.length > fields?.length) {
      const newFields = scheduleDates
        .slice(fields?.length)
        .map((date: Date) => ({ date, fore: '', carrier: optionCarrier }));
      append(newFields);
    }

    fields.forEach((field: any, index: number) => {
      const date = scheduleDates.find((date) => isSameDay(date, field.date));
      if (!date) {
        remove(index);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleDates, fields, append, remove]);

  // const getMinDay = () => {
  //   const currentDate = new Date();

  //   if (currentDate.getDay() === 4) {
  //     const plusDate = currentDate.getHours() >= 15 ? 5 : 4;
  //     // Thursday
  //     return new Date(new Date().setDate(currentDate.getDate() + plusDate));
  //   } else if (currentDate.getDay() === 5) {
  //     const plusDate = currentDate.getHours() >= 15 ? 5 : 4;
  //     // Friday
  //     return new Date(new Date().setDate(currentDate.getDate() + plusDate));
  //   } else if (currentDate.getDay() === 6) {
  //     // Saturday
  //     return new Date(new Date().setDate(currentDate.getDate() + 4));
  //   } else if (currentDate.getDay() === 0) {
  //     // Sunday
  //     return new Date(new Date().setDate(currentDate.getDate() + 3));
  //   } else {
  //     const plusDate = currentDate.getHours() >= 15 ? 3 : 2;
  //     return new Date(new Date().setDate(currentDate.getDate() + plusDate));
  //   }
  // };

  const disabledDates = (date: Date) =>
    isWeekend(date) || date < minDay || scheduleDates.some((d) => isSameDay(d, date));

  const handleDateChange = (newValue: Date, id: string) => {
    const array = [...(scheduleDates ?? [])];
    if (newValue) {
      const date = startOfDay(newValue);
      const currentDate = fields.find((item) => item?.id === id) as Record<string, any>;
      const index = findIndexDate(array, currentDate?.date);
      if (index !== -1) {
        array[index] = date;
        update(index, { date, fore: '', carrier: optionCarrier });
      }
      setDates(array);
    }
  };

  return (
    <>
      <Scrollbar sx={{ maxHeight: 'calc(100vh - 354px)' }}>
        <Stack sx={{ gap: '16px' }}>
          {fields.map((item: any, index) => (
            <InputStyle key={item.id}>
              <RowStyle>
                <DatePickerFieldStyle
                  name={`items[${index}].date`}
                  inputFormat="dd/MM/yyyy"
                  // minDate={getMinDay()}
                  textPlaceholder="Choose date"
                  // shouldDisableDate={(date) => date.getDay() === 0 || date.getDay() === 6}
                  shouldDisableDate={disabledDates}
                  textFieldProps={{ disabled: true }}
                  onChange={(date) => handleDateChange(date, item?.id)}
                />
                <TextFieldStyle
                  name={`items[${index}].fore`}
                  placeholder="Forecasted capacity"
                  variant="outlined"
                  type="number"
                  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <UnionIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <IconButton size="small" onClick={() => handleRemove(index)}>
                  <CloseIcon />
                </IconButton>
              </RowStyle>
              <RowStyle>
                <FormControlLabelStyle
                  control={
                    <AutocompleteStyle
                      multiple
                      options={optionCarrier}
                      disableClearable
                      getOptionLabel={(option: any) => option.name}
                      defaultValue={[...item?.carrier]}
                      onChange={(event, value) => {
                        setValue(`items[${index}].carrier`, value);
                      }}
                      isOptionEqualToValue={(option: any, value: any) => option?.id === value?.id}
                      renderOption={(props, option: any, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option?.name}
                        </li>
                      )}
                      style={{ width: '100%' }}
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Select Carriers" />
                      )}
                    />
                  }
                  label={
                    <InputLabel id="region-label">
                      <Typography sx={{ fontWeight: 500, fontSize: 13, color: '#313131' }}>
                        Carriers *
                      </Typography>
                    </InputLabel>
                  }
                  labelPlacement="top"
                />
              </RowStyle>
            </InputStyle>
          ))}
        </Stack>
      </Scrollbar>
    </>
  );
}
