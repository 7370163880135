import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import { Typography, MenuItem, Dialog, DialogActions, Button, DialogTitle } from '@mui/material';
// components
import MenuPopover from 'components/MenuPopover';
import { IconButtonAnimate } from 'components/animate';
import ProfilePicture from 'components/ProfilePicture';
// hooks
import useAuth from 'hooks/useAuth';
import useIsMountedRef from 'hooks/useIsMountedRef';
// redux
import { clearLogout } from 'redux/slices/global';
import { dispatch } from 'redux/store';
// routes
import { PATH_PAGE } from 'routes/paths';

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const navigate = useNavigate();

  const { logout, user } = useAuth();

  const isMountedRef = useIsMountedRef();

  const [open, setOpen] = useState<HTMLElement | null>(null);
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleOpenDialog = () => {
    handleClose();
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleLogout = async () => {
    try {
      const username: string | null = localStorage.getItem('username');
      if (username) {
        await logout(username);
        navigate(PATH_PAGE.login, { replace: true });
        dispatch(clearLogout());

        if (isMountedRef.current) {
          handleClose();
        }
      }
    } catch (error) {
      dispatch(clearLogout());
      navigate(PATH_PAGE.login, { replace: true });
    }
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <ProfilePicture displayName={`${user?.firstName} ${user?.lastName}`} />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <MenuItem sx={{ m: 1 }} onClick={handleOpenDialog}>
          <Typography variant="button">Logout </Typography>
        </MenuItem>
      </MenuPopover>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>
          <Typography>Log out of My LiveStock Transport</Typography>
        </DialogTitle>
        <DialogActions>
          <Button autoFocus onClick={handleCloseDialog}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleLogout}>
            Logout
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
