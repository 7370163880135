import { memo } from 'react';

function UnionIconGrey1012() {
  return (
    <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.6"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7889 1.61491C11.703 1.13301 10.5377 1.03084 9.53118 1.74632C9.33257 1.89239 9.21333 1.84859 9.08094 1.62948C8.59098 0.826276 7.95525 0.271432 7.08128 0.0815639C7.0045 0.0837295 6.92742 0.0839611 6.85014 0.0832655L6.83546 0.0759277L6.80797 0.0828002C6.751 0.0820641 6.69392 0.0809243 6.63678 0.0797831C6.26375 0.072334 5.88784 0.064827 5.51866 0.169168C4.88302 0.373608 4.37977 0.797054 4.03547 1.38123C3.75732 1.83391 3.519 1.8924 3.12169 1.60024C2.28736 0.972355 1.38688 1.13289 0.49958 1.42503C0.0228335 1.58567 -0.109643 1.90694 0.0890719 2.40345C0.260622 2.82231 0.500191 3.19121 0.744837 3.56792L0.804144 3.65933C1.22797 4.33113 1.74444 4.69621 2.55226 4.60848C3.41926 4.50704 3.42626 4.56375 3.54262 5.50532L3.54548 5.52851C3.59685 5.93802 3.68224 6.29076 3.76628 6.63792C3.96395 7.45449 4.15415 8.24021 3.87656 9.66122C3.88977 10.4206 4.32683 10.5432 4.92274 10.6892C5.32005 10.733 5.74261 10.764 5.98103 10.6764C6.36503 10.9977 6.83886 11.003 7.26268 10.7109C7.47446 10.7459 7.72451 10.699 7.95167 10.6564L7.95171 10.6564C8.0086 10.6458 8.06405 10.6354 8.11711 10.6266C8.16597 10.6163 8.23518 10.6092 8.31557 10.6009C8.69283 10.5619 9.31643 10.4974 9.23998 9.96772L9.08094 8.67825C8.93368 7.9856 9.11337 7.1757 9.29536 6.35537C9.39603 5.90162 9.4974 5.44468 9.54455 5.00262C9.55008 4.94173 9.55273 4.86305 9.55562 4.77719V4.77717C9.56659 4.45126 9.58104 4.02183 9.76967 4.06802C11.0675 4.36006 11.9283 3.65913 12.2064 3.39622C12.5507 3.07516 12.9214 1.67327 12.7889 1.61491Z"
        fill="#313131"
      />
    </svg>
  );
}

export default memo(UnionIconGrey1012);
