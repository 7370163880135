import { add } from 'date-fns';
import _mock from './_mock';
import { randomInArray, randomNumberRange } from './funcs';
import uuidv4 from 'utils/uuidv4';

// ----------------------------------------------------------------------

export const _farms_tallyResponses = [...Array(10)].map((_, index) => ({
  farmId: _mock.id(index),
  farmName: _mock.name.fullName(index + 1),
  lastPickUp: randomNumberRange(0, 500),
  lastPickUpDate: add(new Date(), { days: -7, hours: index }),
  farmNumber: _mock.phoneNumber(randomNumberRange(0, 10)),
  submitted: randomNumberRange(0, 500),
  accuracy: randomInArray(['0', '+2', '-10']),
  status: randomInArray(['Priority', '']),
}));

export const _farms_nonresponsed = [...Array(10)].map((_, index) => ({
  farmId: _mock.id(index),
  farmName: _mock.name.fullName(index + 1),
  lastPickUp: randomNumberRange(0, 500),
  lastPickUpDate: add(new Date(), { days: -5, hours: index }),
  farmNumber: _mock.phoneNumber(randomNumberRange(0, 10)),
  submitted: randomNumberRange(0, 500),
  accuracy: randomInArray(['0', '+2', '-10']),
  status: randomInArray(['Priority', '']),
}));

export const _farms_removedFromRun = [...Array(5)].map((_, index) => ({
  farmId: _mock.id(index + 10),
  farmName: _mock.name.fullName(index + 10),
  lastPickUp: randomNumberRange(0, 500),
  lastPickUpDate: add(new Date(), { days: -5, hours: index }),
  farmNumber: _mock.phoneNumber(randomNumberRange(0, 10)),
  submitted: randomNumberRange(0, 500),
  accuracy: randomInArray(['0', '+2', '-10']),
  status: randomInArray(['Priority', '']),
}));

export const _farms_routes = [
  {
    id: uuidv4(),
    farmName: _mock.name.fullName(1),
    forecastedTally: randomNumberRange(0, 500),
    state: 'New York',
    latitude: -36.850500150521505,
    longitude: 174.74932014745272,
    address: _mock.address.fullAddress(1),
    contactPerson: _mock.name.fullName(1),
    farmNumber: _mock.phoneNumber(1),
  },
  {
    id: uuidv4(),
    farmName: _mock.name.fullName(2),
    forecastedTally: randomNumberRange(0, 500),
    state: 'California',
    latitude: -36.85075872897153,
    longitude: 174.75874422123997,
    address: _mock.address.fullAddress(2),
    contactPerson: _mock.name.fullName(2),
    farmNumber: _mock.phoneNumber(2),
  },
  {
    id: uuidv4(),
    farmName: _mock.name.fullName(3),
    forecastedTally: randomNumberRange(0, 500),
    state: 'Illinois',
    latitude: -36.84623168681607,
    longitude: 174.76063676542674,
    address: _mock.address.fullAddress(3),
    contactPerson: _mock.name.fullName(3),
    farmNumber: _mock.phoneNumber(3),
  },
  {
    id: uuidv4(),
    farmName: _mock.name.fullName(4),
    forecastedTally: randomNumberRange(0, 500),
    state: 'Texas',
    latitude: -36.857942338712725,
    longitude: 174.75575846438582,
    address: _mock.address.fullAddress(4),
    contactPerson: _mock.name.fullName(4),
    farmNumber: _mock.phoneNumber(4),
  },
  {
    id: uuidv4(),
    farmName: _mock.name.fullName(5),
    forecastedTally: randomNumberRange(0, 500),
    state: 'Arizona',
    latitude: -36.877519307343526,
    longitude: 174.74578659618794,
    address: _mock.address.fullAddress(5),
    contactPerson: _mock.name.fullName(5),
    farmNumber: _mock.phoneNumber(5),
  },
  {
    id: uuidv4(),
    farmName: _mock.name.fullName(6),
    forecastedTally: randomNumberRange(0, 500),
    state: 'California',
    latitude: -36.88049883982875,
    longitude: 174.75044837934476,
    address: _mock.address.fullAddress(6),
    contactPerson: _mock.name.fullName(6),
    farmNumber: _mock.phoneNumber(6),
  },
  {
    id: uuidv4(),
    farmName: _mock.name.fullName(7),
    forecastedTally: randomNumberRange(0, 500),
    state: 'Texas',
    latitude: -36.895481692801795,
    longitude: 174.7563612064184,
    address: _mock.address.fullAddress(7),
    contactPerson: _mock.name.fullName(7),
    farmNumber: _mock.phoneNumber(7),
  },
];
