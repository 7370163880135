// @mui
import { Stack, TypographyProps, Typography, styled } from '@mui/material';

const RootStyle = styled(Stack)(({ theme }) => ({}));

const TypographyStyle = styled(Typography)(({ theme }) => ({
  lineHeight: '1.2',
  textAlign: 'center',
  fontWeight: 900,
  letterSpacing: '0px',
}));

function LogoLiveStockTransport({ variant = 'h1', ...other }: TypographyProps) {
  let fontSize = variant === 'h1' ? 60 : 30;
  return (
    <RootStyle>
      <TypographyStyle variant={variant} fontSize={fontSize} color="#eb1c24" {...other}>
        My<span style={{ color: '#ffe100' }}>LiveStock</span>
        <div style={{ color: 'white' }}>Transport</div>
      </TypographyStyle>
    </RootStyle>
  );
}

export default LogoLiveStockTransport;
