import React, { memo } from 'react';
// @mui
import { Box, BoxProps } from '@mui/material';

type IconProps = {
  color?: string;
  width?: number;
  height?: number;
} & BoxProps;

const ICON =
  'M20 27.35C21.7645 27.35 23.3126 26.6754 24.5354 25.4037C25.7556 24.1347 26.375 22.5622 26.375 20.8C26.375 19.0378 25.7556 17.4653 24.5354 16.1963C23.3126 14.9246 21.7645 14.25 20 14.25C18.2355 14.25 16.6874 14.9246 15.4646 16.1963C14.2444 17.4653 13.625 19.0378 13.625 20.8C13.625 22.5622 14.2444 24.1347 15.4646 25.4037C16.6874 26.6754 18.2355 27.35 20 27.35ZM20 49.3145C14.0415 43.9063 9.60929 38.923 6.6452 34.3668C3.46239 29.4745 2 25.1398 2 21.32C2 15.2662 3.85406 10.6446 7.41569 7.22837C11.0859 3.70803 15.2537 2 20 2C24.7463 2 28.9141 3.70803 32.5843 7.22837C36.1459 10.6446 38 15.2662 38 21.32C38 25.1398 36.5376 29.4745 33.3548 34.3668C30.3907 38.923 25.9585 43.9063 20 49.3145Z';

function PinMapIcon({ color = '#313131', width = 10, height = 21, ...other }: IconProps) {
  return (
    <Box {...other}>
      <svg
        width="40"
        height={height}
        viewBox="0 0 40 52"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d={ICON} fill={color} stroke="#313131" strokeWidth="3" />
      </svg>
    </Box>
  );
}

export default memo(PinMapIcon);
