import * as api from 'utils/axios';
import * as endpoints from 'services/processor/endpoints';

export const getProcessorList = () =>
  api.sendGet(endpoints.PROCESSOR_LIST, {
    headers: { 'Content-Type': 'application/json', accept: 'application/json' },
  });

export const addCarriersToProcessor = (
  regionId: string,
  param: {
    carrierIds: string[];
    deletedCarrierIds: string[];
  }
) => api.sendPatch(endpoints.UPDATE_CARRIERS_TO_PROCESSOR.replace('{regionId}', regionId), param);
