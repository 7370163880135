import * as api from 'utils/axios';
import * as endpoints from 'services/map/endpoints';
import { MAP_TOKEN } from 'config';

export const getLongLat = (address: string, country: string = 'NZ') =>
  api.sendGet(
    endpoints.GET_LONG_LAT.replace('{address}', address) + MAP_TOKEN + `&country=${country}`,
    {}
  );

export const getOptimizedTrips = (address: string) =>
  api.sendGet(endpoints.OPTIMIZE_TRIP.replace('{address}', address) + MAP_TOKEN, {});

export const getDrivingDirections = (coords: string) =>
  api.sendGet(
    `${endpoints.GET_DIRECTION}${coords}?access_token=${MAP_TOKEN}&geometries=geojson`,
    {}
  );
