import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Stack, Drawer } from '@mui/material';
// redux
import { useSelector } from 'redux/store';
// config
import { NAVBAR } from '../../../config';
// components
import Logo from '../../../components/Logo';
import Scrollbar from '../../../components/Scrollbar';
import { NavSectionVertical } from '../../../components/nav-section';
// hooks
import useResponsive from 'hooks/useResponsive';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('xl')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

const BGColor = '#02344A';
// ----------------------------------------------------------------------

type Props = {
  isNavOpen: boolean;
  onCloseNav: VoidFunction;
};

export default function NavbarVertical({ isNavOpen, onCloseNav }: Props) {
  const { pathname } = useLocation();
  const { navConfig } = useSelector((state) => state.global);

  useEffect(() => {
    if (isNavOpen) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const isDesktop = useResponsive('up', 'lg');

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          padding: '30px 50px 10px 20px',
          flexShrink: 0,
        }}
      >
        <Stack spacing={1} direction="row" alignItems="center" justifyContent="flex-start">
          <Logo />
        </Stack>
      </Stack>

      <NavSectionVertical navConfig={navConfig} />
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: NAVBAR.BASE_WIDTH,
        position: 'absolute',
      }}
    >
      <Drawer
        open={isDesktop ? true : isNavOpen}
        onClose={onCloseNav}
        variant={isDesktop ? 'persistent' : 'temporary'}
        PaperProps={{
          sx: {
            width: NAVBAR.BASE_WIDTH,
            borderRightStyle: '1px solid #F2F2F2',
            bgcolor: BGColor,
          },
        }}
        {...(!isDesktop && {
          ModalProps: {
            keepMounted: true,
          },
        })}
      >
        {renderContent}
      </Drawer>
    </RootStyle>
  );
}
