import React, { memo } from 'react';
// @mui
import { Box, BoxProps } from '@mui/material';

type IconProps = {
  color?: string;
  width?: number;
  height?: number;
} & BoxProps;

function UnionMinIcon({ color = '', width = 10, height = 10, ...other }: IconProps) {
  return (
    <Box {...other}>
      <svg
        width="13"
        height="11"
        viewBox="0 0 13 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.6"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.80797 0.0830444C6.751 0.0823083 6.69392 0.0811684 6.63678 0.0800273C6.26375 0.0725781 5.88784 0.0650711 5.51866 0.169412C4.88302 0.373852 4.37977 0.797298 4.03547 1.38148C3.75732 1.83415 3.519 1.89264 3.12169 1.60049C2.28736 0.972599 1.38688 1.13313 0.49958 1.42528C0.0228335 1.58592 -0.109643 1.90718 0.0890719 2.40369C0.260622 2.82256 0.500191 3.19145 0.744837 3.56817L0.804144 3.65957C1.22797 4.33137 1.74444 4.69645 2.55226 4.60873C3.41926 4.50728 3.42626 4.56399 3.54262 5.50556L3.54548 5.52876C3.59685 5.93826 3.68224 6.291 3.76628 6.63817C3.96395 7.45474 4.15415 8.24045 3.87656 9.66147C3.88977 10.4209 4.32683 10.5434 4.92274 10.6894C5.32005 10.7332 5.74261 10.7643 5.98103 10.6767C6.36503 10.998 6.83886 11.0033 7.26268 10.7111C7.47446 10.7461 7.72451 10.6993 7.95167 10.6567L7.95171 10.6567C8.0086 10.646 8.06405 10.6356 8.11711 10.6268C8.16597 10.6166 8.23518 10.6094 8.31557 10.6011C8.69283 10.5621 9.31643 10.4977 9.23998 9.96796L9.08094 8.6785C8.93368 7.98585 9.11337 7.17594 9.29536 6.35561C9.39603 5.90186 9.4974 5.44492 9.54455 5.00287C9.55008 4.94197 9.55273 4.86329 9.55562 4.77743V4.77741C9.56659 4.45151 9.58104 4.02207 9.76967 4.06826C11.0675 4.3603 11.9283 3.65937 12.2064 3.39646C12.5507 3.0754 12.9214 1.67352 12.7889 1.61516C11.703 1.13325 10.5377 1.03108 9.53118 1.74656C9.33257 1.89264 9.21333 1.84883 9.08094 1.62972C8.59098 0.82652 7.95525 0.271676 7.08128 0.0818081C7.0045 0.0839736 6.92742 0.0842052 6.85014 0.0835096L6.83546 0.0761719L6.80797 0.0830444Z"
          fill="#313131"
        />
      </svg>
    </Box>
  );
}

export default memo(UnionMinIcon);
