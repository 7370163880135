import { Dispatch, createSlice } from '@reduxjs/toolkit';
// @types
import {
  FarmRouteType,
  OptimizedRouteDataType,
  OptimizedTruckRouteType,
  RouteType,
  TruckRouteType,
  TruckType,
  TruckInfoType,
  TruckTooltip,
} from '_types/route';
// redux
import { cloneDeep } from 'lodash';
import { dispatch } from '../store';
// services
import * as services from 'services/truck';

// ----------------------------------------------------------------------

type InitialState = {
  isLoading: boolean;
  error: Error | string | null;

  // MAP
  initialViewState: {
    latitude: number;
    longitude: number;
    zoom: number;
  };
  selectedRoute: any;
  tooltipRoute: FarmRouteType | RouteType | null;
  rightMenu: any;
  pinnedRoutes: FarmRouteType[];

  // TRUCK
  isLoadingTruckRoute: boolean;
  errorTrucks: Error | string | null;
  trucks: TruckType[];
  truck: TruckType | null;
  optimizedTruckRoute: Partial<OptimizedTruckRouteType>[];
  tooltipTruck: TruckTooltip | null;

  farmRoutes: FarmRouteType[];
  farmRouteAdded: FarmRouteType[];

  trucksGenerate: TruckType[];
  isClickGenerate: boolean;
  isChangeMap: boolean;
};

const initialState: InitialState = {
  isLoading: false,
  error: null,

  // MAP
  initialViewState: {
    // latitude: -36.85811017324156,
    // longitude: 174.76260332765614,
    // TODO K ADD NEW ZEALAND LANG - LAT
    latitude: -41.8388752215127,
    longitude: 171.779900195937,
    zoom: 5,
  },
  selectedRoute: null,
  tooltipRoute: null,
  rightMenu: null,
  pinnedRoutes: [],

  // TRUCK
  isLoadingTruckRoute: false,
  errorTrucks: null,
  trucks: [],
  truck: null,
  optimizedTruckRoute: [],
  tooltipTruck: null,

  farmRoutes: [],
  farmRouteAdded: [],
  trucksGenerate: [],
  isClickGenerate: false,
  isChangeMap: true,
};

const slice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    resetMapsData: () => initialState,

    selectRoute: (state, action) => {
      state.selectedRoute = action.payload;
    },

    // TOOLTIP
    setTooltipRoute: (state, action) => {
      state.tooltipRoute = action.payload;
    },

    // RIGHT MENU
    updateRightMenu: (state, action) => {
      state.rightMenu = action.payload;
    },
    // UPDATE PINNED
    updatePinnedRoutes: (state, action) => {
      state.pinnedRoutes = action.payload;
    },

    // GET TRUCKS
    startTruckLoading(state) {
      state.isLoadingTruckRoute = true;
    },

    hasTruckError(state, action) {
      state.isLoadingTruckRoute = false;
      state.errorTrucks = action.payload;
    },

    getTrucks(state, action) {
      state.isLoadingTruckRoute = false;
      state.trucks = action.payload;
    },

    // GET TRUCK
    getTruck(state, action) {
      state.isLoading = false;
      state.truck = action.payload;
    },

    // CREATE TRUCK
    createTruck(state, action) {
      const newTruck = action.payload;

      state.isLoading = false;
      state.trucks = [...state.trucks, newTruck];
    },

    deleteTruck(state, action) {
      // delete truck
      const trucks = state.trucks?.filter((item) => item.id !== action.payload);
      state.trucks = trucks;

      // reallocate farm
      const farmRouteReAdd: FarmRouteType[] = cloneDeep(state.farmRouteAdded);

      state.farmRouteAdded = farmRouteReAdd.filter((item) => item.truckId !== action.payload);

      const farmRoute = farmRouteReAdd.filter((item) => item.truckId === action.payload);
      farmRoute?.map((item) => ((item.truckId = undefined), (item.color = undefined)));

      state.farmRoutes = [...farmRoute, ...state.farmRoutes];
    },

    reallocateFarm(state, action) {
      // delete farm  truck
      const farmRouteAdded = state.farmRouteAdded?.filter(
        (item) => item.truckId !== action.payload
      );

      // reallocate farm
      const reallocateFarm: FarmRouteType[] = state.farmRouteAdded?.filter(
        (item) => item.truckId === action.payload
      );
      reallocateFarm?.map((item) => ((item.truckId = undefined), (item.color = undefined)));

      state.farmRouteAdded = farmRouteAdded;
      state.farmRoutes = [...reallocateFarm, ...state.farmRoutes];
    },

    // UPDATE ROUTE TO TRUCK
    updateFarmRouteToTruck(state, action) {
      // add new Item to
      const newFarmRoute: FarmRouteType[] = action.payload;
      state.farmRouteAdded = [...state.farmRouteAdded, ...newFarmRoute];

      // remove item farmRoutes
      let newFarmRoutes = cloneDeep(state.farmRoutes);
      newFarmRoute?.map((item) => {
        newFarmRoutes = newFarmRoutes.filter((farmItem) => item.id !== farmItem.id);
      });

      // set filter to farmRoutes
      state.farmRoutes = newFarmRoutes;
    },

    removeFarmFromTruck(state, action) {
      // delete farm
      const farmRoute: FarmRouteType[] = state.farmRouteAdded?.filter(
        (item) => item.id !== action.payload
      );
      const removeFarmRoute = state.farmRouteAdded?.filter((item) => item.id === action.payload);

      // remove farm form farmRouteAdded
      state.farmRouteAdded = farmRoute;

      // add farm to map
      removeFarmRoute?.map((item) => ((item.truckId = undefined), (item.color = undefined)));
      state.farmRoutes = [...removeFarmRoute, ...state.farmRoutes];
    },

    // KHOI TODO INIT FARMROUTE
    updateFarmRoutes(state, action) {
      state.farmRoutes = action.payload;
    },

    // UPDATE ROUTE GENERATE
    updateTrucksGenerate(state, action) {
      state.trucksGenerate = action.payload;
    },

    // UPDATE ROUTE GENERATE
    updateIsClickGenerate(state, action) {
      state.isClickGenerate = action.payload;
    },

    // ROUTE MANAGEMENT: UPDATE ADDED ROUTES WITH NEW ORDER
    updateAddedRoutes(state, action) {
      const newRoutes = action.payload;
      const truckId = newRoutes[0]?.truckId;

      const routes = state.farmRouteAdded.filter((route) => route.truckId !== truckId);

      state.farmRouteAdded = [...routes, ...newRoutes];
    },

    // ROUTE MANAGEMENT: UPDATE
    updateOptimizedTruckRoute(state, action) {
      const { truckId, truckRoute } = action.payload;
      const parsedTruckRoute = {
        ...truckRoute,
        geometry: truckRoute.geometry.length === 1 ? truckRoute.geometry[0] : truckRoute.geometry,
      };
      const truckIndex = state.optimizedTruckRoute.findIndex((item) => item.truckId === truckId);
      if (truckIndex !== -1) {
        state.optimizedTruckRoute[truckIndex].optimizedRouteData = parsedTruckRoute;
      } else {
        state.optimizedTruckRoute.push({
          truckId,
          optimizedRouteData: parsedTruckRoute,
        });
      }

      state.isLoadingTruckRoute = false;
    },

    // DELIVERY: UPDATE ROUTE PICKEDUP TALLY
    updateRoutePickedUpTally(state, action) {
      const { truckId, route } = action.payload;

      const truckIndex = state.trucks.findIndex((item) => item.id === truckId);

      if (truckIndex !== -1) {
        const updatedTrucks = [...state.trucks] as TruckRouteType[];
        const routeIndex = updatedTrucks[truckIndex].routes.findIndex(
          (item) => item.id === route.id
        );

        if (routeIndex !== -1) {
          updatedTrucks[truckIndex].routes[routeIndex] = {
            ...updatedTrucks[truckIndex].routes[routeIndex],
            ...route,
          };

          state.trucks = updatedTrucks;
        }
      }
    },

    // ROUTE CONFIRMED: UPDATE ROUTES FROM API TO FarmRouteAdded
    updateFarmRouteAdded(state, action) {
      const farmRoutes = action.payload;
      state.farmRoutes = [];
      state.farmRouteAdded = farmRoutes;
    },

    // ROUTE CONFIRMED: RESET ROUTES
    resetRoutes(state) {
      state.isClickGenerate = false;
      state.trucksGenerate = [];
      state.trucks = [];
      state.farmRouteAdded = [];
    },

    // CATCH MAP CHANGE
    updateIsChangeMap(state, action) {
      state.isChangeMap = action.payload;
    },

    updateTruckEstimatedTimeArrival(state, action) {
      const { truckId, time, isDeliveryDay } = action.payload;
      const truckList = cloneDeep(state.trucks);
      truckList.map((item: any) => {
        if (item.id === truckId) {
          if (isDeliveryDay) {
            if (
              item?.routes[0]?.estimatedArrivalTime ||
              item?.routes[0]?.estimatedArrivalTime === null ||
              item?.routes[0]?.estimatedArrivalTime === ''
            ) {
              item.routes[0].estimatedArrivalTime = time;
            } else {
              item.routes[0] = { ...item.routes[0], estimatedArrivalTime: time };
            }
          } else {
            item.estimatedArrivalTime = time;
          }
        }
      });
      state.trucks = truckList;
    },

    setTooltipTruck(state, action) {
      state.tooltipTruck = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

export const { actions } = slice;

// ----------------------------------------------------------------------

export function selectRoute(route: any) {
  return async () => {
    dispatch(slice.actions.selectRoute(route));
  };
}

// ----------------------------------------------------------------------

export function updatePinnedRoutes(routes: FarmRouteType[]) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.updatePinnedRoutes(routes));
  };
}
// ----------------------------------------------------------------------

export function setTooltipRoute(route: any) {
  return async () => {
    dispatch(slice.actions.setTooltipRoute(route));
  };
}
// ----------------------------------------------------------------------

export function updateRightMenu(route: any) {
  return async () => {
    dispatch(slice.actions.updateRightMenu(route));
  };
}
// ----------------------------------------------------------------------

export function createTruck(newTruck: Partial<TruckType>) {
  return () => {
    dispatch(slice.actions.startLoading());
    return new Promise<any>(function (res, rej) {
      dispatch(slice.actions.createTruck(newTruck));
    }).then(() => newTruck);
  };
}

// ----------------------------------------------------------------------

export function deleteTruck(truckId?: string) {
  return () => {
    dispatch(slice.actions.deleteTruck(truckId));
  };
}
// ----------------------------------------------------------------------

export function reallocateFarm(truckId?: string) {
  return () => {
    dispatch(slice.actions.reallocateFarm(truckId));
  };
}
// ----------------------------------------------------------------------

export function updateFarmRouteToTruck(newFarmRoute: FarmRouteType[]) {
  return () => {
    dispatch(slice.actions.updateFarmRouteToTruck(newFarmRoute));
  };
}
// ----------------------------------------------------------------------

export function removeFarmFromTruck(truckId?: string) {
  return () => {
    dispatch(slice.actions.removeFarmFromTruck(truckId));
  };
}

// ----------------------------------------------------------------------

export function updateFarmRoutes(farmRoutes: any) {
  return () => {
    dispatch(slice.actions.updateFarmRoutes(farmRoutes));
  };
}

// ----------------------------------------------------------------------
export function updateTrucksGenerate(trucks: TruckType[]) {
  return () => {
    dispatch(slice.actions.updateTrucksGenerate(trucks));
  };
}
// ----------------------------------------------------------------------
export function updateIsClickGenerate(isClick: boolean) {
  return () => {
    dispatch(slice.actions.updateIsClickGenerate(false));
    setTimeout(() => {
      dispatch(slice.actions.updateIsClickGenerate(isClick));
    }, 100);
  };
}
// ----------------------------------------------------------------------
export function updateIsChangeMap(isChange: boolean) {
  return () => {
    dispatch(slice.actions.updateIsChangeMap(isChange));
  };
}
// ----------------------------------------------------------------------

export function updateRoutesAddedtoTruck(newRoutes: FarmRouteType[]) {
  return () =>
    new Promise<any>(function (res, rej) {
      dispatch(slice.actions.startTruckLoading());
      dispatch(slice.actions.updateAddedRoutes(newRoutes));
    }).then(() => [...newRoutes]);
}

// ----------------------------------------------------------------------

export function getAllTrucks(runId: string, carrierId: string) {
  return (dispatch: Dispatch) => {
    dispatch(slice.actions.startTruckLoading());
    return new Promise((resolve, reject) => {
      services
        .getAllTrucks(runId, carrierId)
        .then((response: any) => {
          const responseData = response?.data ?? [];
          dispatch(slice.actions.getTrucks(responseData));
          resolve(responseData);
        })
        .catch((error: any) => {
          dispatch(slice.actions.hasTruckError(error));
          reject(error);
        });
    });
  };
}

// ----------------------------------------------------------------------

export function updateRoutePickedUpTally(truckId: string, route: RouteType) {
  return () => dispatch(slice.actions.updateRoutePickedUpTally({ truckId, route }));
}

// ----------------------------------------------------------------------

export function updateOptimizedTruckRoute(truckId: string, truckRoute: OptimizedRouteDataType) {
  return () => dispatch(slice.actions.updateOptimizedTruckRoute({ truckId, truckRoute }));
}
// ----------------------------------------------------------------------
export function updateTruckEstimatedTimeArrival(
  truckId: string,
  time: string,
  isDeliveryDay = false
) {
  return () => {
    dispatch(slice.actions.updateTruckEstimatedTimeArrival({ truckId, time, isDeliveryDay }));
  };
}
//----------------------------------------------------------------------

export function resetRoutes() {
  return () => dispatch(slice.actions.resetRoutes());
}

// ----------------------------------------------------------------------
export function setTooltipTruck(truck: TruckTooltip | null) {
  return async () => {
    dispatch(slice.actions.setTooltipTruck(truck));
  };
}
