import { Suspense, lazy, ElementType } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// hooks
import useAuth from 'hooks/useAuth';
// layouts
import DashboardLayout from 'layouts/dashboard';
import LogoOnlyLayout from 'layouts/LogoOnlyLayout';
// guards
import GuestGuard from 'guards/GuestGuard';
import AuthGuard from 'guards/AuthGuard';
import RoleBasedGuard from 'guards/RoleBasedGuard';
// components
import LoadingScreen from 'components/LoadingScreen';
// constants
import { PERMISSION } from 'utils/constants';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth();

  const isDashboard = true || (pathname.includes('/') && isAuthenticated);

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <Navigate to="login" replace />,
    },
    {
      path: 'login',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
    },
    {
      path: '*',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'run-management',
          element: <RunManagement />,
        },
        {
          path: 'dashboard',
          element: (
            <RoleBasedGuard
              hasContent
              disallowedRoles={[PERMISSION.ROLE_CARRIER_ADMIN, PERMISSION.ROLE_DRIVER]}
            >
              <Dashboard />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'farm-management',
          element: (
            <RoleBasedGuard
              hasContent
              disallowedRoles={[
                PERMISSION.ROLE_CARRIER_ADMIN,
                PERMISSION.ROLE_DRIVER,
                PERMISSION.ROLE_PROCESSOR_ADMIN,
              ]}
            >
              <FarmManagement />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'run-management/:runId/edit',
          element: <RunDetailsEdit />,
        },
        {
          path: 'processor-management',
          element: (
            <RoleBasedGuard
              hasContent
              disallowedRoles={[PERMISSION.ROLE_CARRIER_ADMIN, PERMISSION.ROLE_DRIVER]}
            >
              <ProcessorManagement />
            </RoleBasedGuard>
          ),
        },
      ],
    },
    {
      path: '*',
      element: (
        <AuthGuard>
          <DashboardLayout isTopbar={false} />
        </AuthGuard>
      ),
      children: [
        {
          path: 'run-management/:runId/:carrierId/route-management/edit',
          element: (
            <RoleBasedGuard hasContent disallowedRoles={[PERMISSION.ROLE_DRIVER]}>
              <RouteEdit />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'run-management/:runId/:carrierId/route-confirmed/edit',
          element: (
            <RoleBasedGuard hasContent disallowedRoles={[PERMISSION.ROLE_DRIVER]}>
              <RouteEdit />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'run-management/:runId/:carrierId/delivery-day',
          element: <DeliveryDay />,
        },
      ],
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

//ERROR
const Page500 = Loadable(lazy(() => import('pages/Page500')));
const Page403 = Loadable(lazy(() => import('pages/Page403')));
const Page404 = Loadable(lazy(() => import('pages/Page404')));
// AUTHENTICATION
const Login = Loadable(lazy(() => import('pages/Login')));
// MAIN
const Dashboard = Loadable(lazy(() => import('pages/Dashboard')));
const RunManagement = Loadable(lazy(() => import('pages/run/list/RunManagement')));
const RunDetailsEdit = Loadable(lazy(() => import('pages/run/RunDetailsEdit')));
const RouteEdit = Loadable(lazy(() => import('pages/run/RouteEdit')));
const DeliveryDay = Loadable(lazy(() => import('pages/run/DeliveryDay')));

const FarmManagement = Loadable(lazy(() => import('pages/FarmManagement')));
const ProcessorManagement = Loadable(lazy(() => import('pages/ProcessorManagement')));
