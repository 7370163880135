import { useState } from 'react';
import { Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
// config
import { NAVBAR, HEADER } from '../../config';
//
import DashboardHeader from './header';
import NavbarVertical from './navbar/NavbarVertical';
// hooks
import useResponsive from 'hooks/useResponsive';

// ----------------------------------------------------------------------

const MainStyle = styled('main')(({ theme }) => ({
  backgroundColor: '#F1F0EC',
  flexGrow: 1,
  paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT,
}));

// ----------------------------------------------------------------------

export type Props = {
  isTopbar?: boolean;
};

export default function DashboardLayout({ isTopbar = true }: Props) {
  const [open, setOpen] = useState(true);

  const isDesktop = useResponsive('up', 'lg');

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box
      sx={{
        display: { lg: 'flex' },
        minHeight: { lg: 1 },
      }}
    >
      {isTopbar && <DashboardHeader onOpenNav={handleOpen} />}
      <NavbarVertical isNavOpen={open} onCloseNav={handleClose} />

      <MainStyle
        sx={{
          width: `calc(100% - ${isDesktop ? NAVBAR.BASE_WIDTH : 0}px)`,
          pt: isTopbar ? `${HEADER.DASHBOARD_DESKTOP_HEIGHT}px` : 0,
          ml: isDesktop ? `${NAVBAR.BASE_WIDTH}px` : 0,
        }}
      >
        <Outlet />
      </MainStyle>
    </Box>
  );
}
