import * as endpoints from 'services/truck/endpoints';
import * as api from 'utils/axios';
// @types
import { OptimizedTruckRouteType } from '_types/route';

export const getAllTrucks = (runId: string, carrierId?: string) =>
  api.sendGet(endpoints.GET_TRUCKS.replace('{runId}', runId), {
    headers: { 'Content-Type': 'application/json', accept: 'application/json' },
    params: {
      carrierId,
    },
    data: {},
  });

export const updatePickedUpTally = (
  truckId: string,
  truckRouteUpdatePayload: { routeId: string; pickedUpTally: number }[]
) =>
  api.sendPatch(endpoints.UPDATE_PICKUP_TALLY.replace('{truckId}', truckId), {
    truckRouteUpdate: truckRouteUpdatePayload,
  });

export const setTruckComplete = (truckId: string) =>
  api.sendPatch(endpoints.SET_TRUCK_COMPLETE.replace('{truckId}', truckId), {
    isOverallRouteComplete: true,
  });

export const updateTruckRoutes = (payload: OptimizedTruckRouteType[]) =>
  api.sendPatch(endpoints.UPDATE_TRUCK_ROUTES, payload);

export const setRunDetailsComplete = (runId: string, carrierId: string) =>
  api.sendPatch(
    endpoints.SET_RUN_DETAILS_COMPLETE.replace('{runId}', runId).replace('{carrierId}', carrierId),
    {}
  );
