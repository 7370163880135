import { createSlice } from '@reduxjs/toolkit';
// @types
import { NavListProps } from 'components/nav-section/type';
//
import { dispatch } from '../store';
// nav config
import {
  navConfig,
  navConfigCarrier,
  navConfigProcessor,
} from 'layouts/dashboard/navbar/NavConfig';
// constants
import { PERMISSION } from 'utils/constants';
// ----------------------------------------------------------------------

type InitialState = {
  isLoading: boolean;
  error: Error | string | null;
  navConfig: {
    subheader: string;
    items: NavListProps[];
  }[];
};

const initialState: InitialState = {
  isLoading: false,
  error: null,
  navConfig: navConfig,
};

const slice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // UPDATE NAVCONFIG
    updateNavConfig(state, action) {
      state.navConfig = action.payload;
    },
    // LOG OUT CLEAR REDUX TRIGGER
    logout(state) {},
  },
});

// Reducer
export default slice.reducer;

export const { actions } = slice;

// ----------------------------------------------------------------------

export function updateNavConfig(nav: string) {
  return () => {
    try {
      if (nav === PERMISSION.ROLE_CARRIER_ADMIN || nav === PERMISSION.ROLE_DRIVER) {
        dispatch(slice.actions.updateNavConfig(navConfigCarrier));
      } else if (nav === PERMISSION.ROLE_PROCESSOR_ADMIN) {
        dispatch(slice.actions.updateNavConfig(navConfigProcessor));
      } else {
        dispatch(slice.actions.updateNavConfig(navConfig));
      }
    } catch (error) {}
  };
}

// ----------------------------------------------------------------------

export function clearLogout() {
  return () => {
    try {
      dispatch(slice.actions.logout());
    } catch (error) {}
  };
}

// ----------------------------------------------------------------------
