export const order = [
  '#123456',
  '#123456',
  '#123456',
  '#123456',
  '#123456',
  '#123456',
  '#123456',
  '#123456',
  '#123456',
  '#123456',
  '#123456',
];
export const customer = [
  'Sydney Huffman',
  'Sydney Huffman',
  'Sydney Huffman',
  'Sydney Huffman',
  'Sydney Huffman',
  'Sydney Huffman',
  'Sydney Huffman',
  'Sydney Huffman',
  'Sydney Huffman',
  'Sydney Huffman',
  'Sydney Huffman',
];
export const event = [
  { id: 1, name: 'Night Lights Sky Lantern Festival - Salt Lake City', address: 'Salt Lake City' },
  { id: 2, name: 'Night Lights Sky Lantern Festival - Salt Lake City', address: 'Salt Lake City' },
  { id: 3, name: 'Night Lights Sky Lantern Festival - Salt Lake City', address: 'Salt Lake City' },
  { id: 4, name: 'Night Lights Sky Lantern Festival - Salt Lake City', address: 'Salt Lake City' },
  { id: 5, name: 'Night Lights Sky Lantern Festival - Salt Lake City', address: 'Salt Lake City' },
  { id: 6, name: 'Night Lights Sky Lantern Festival - Salt Lake City', address: 'Salt Lake City' },
  { id: 7, name: 'Night Lights Sky Lantern Festival - Salt Lake City', address: 'Salt Lake City' },
  { id: 8, name: 'Night Lights Sky Lantern Festival - Salt Lake City', address: 'Salt Lake City' },
  { id: 9, name: 'Night Lights Sky Lantern Festival - Salt Lake City', address: 'Salt Lake City' },
  { id: 10, name: 'Night Lights Sky Lantern Festival - Salt Lake City', address: 'Salt Lake City' },
  { id: 11, name: 'Night Lights Sky Lantern Festival - Salt Lake City', address: 'Salt Lake City' },
];
export const dateOfPurchase = [
  'Dec 1, 2022',
  'Dec 1, 2022',
  'Dec 1, 2022',
  'Dec 1, 2022',
  'Dec 1, 2022',
  'Dec 1, 2022',
  'Dec 1, 2022',
  'Dec 1, 2022',
  'Dec 1, 2022',
  'Dec 1, 2022',
  'Dec 1, 2022',
];
export const items = ['3', '3', '3', '3', '3', '3', '3', '3', '3', '3', '3'];
export const totalCost = [
  '$330',
  '$330',
  '$330',
  '$330',
  '$330',
  '$330',
  '$330',
  '$330',
  '$330',
  '$330',
  '$330',
];
