import { useState, useRef, useEffect } from 'react';
import { startOfDay, isSameDay } from 'date-fns';
// @mui
import { TextField, Typography, Box, Popover, styled } from '@mui/material';
import { LocalizationProvider, StaticDatePicker, PickersDay, PickersDayProps } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import EventIcon from '@mui/icons-material/Event';
// utils
import { findDate, findIndexDate } from 'utils/date';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: 320,
  minWidth: 320,
  margin: '0 auto',
  textAlign: 'center',
  boxShadow:
    ' 0px 5px 5px -3px rgb(145 158 171 / 20%), 0px 8px 10px 1px rgb(145 158 171 / 14%), 0px 3px 14px 2px rgb(145 158 171 / 12%)',
  '.MuiPaper-root': {},
}));

const PickersDayStyle = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== 'selected',
})(({ theme, selected }) => ({
  ...(selected && {
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.main,
    },
  }),
}));

// ----------------------------------------------------------------------

type DateType = Date | null;

type Props = {
  open: boolean;
  onClose: () => void;
  dates: Date[];
  setDates: React.Dispatch<React.SetStateAction<Date[]>>;
  disabledDates?: (date: Date) => boolean;
};

// ----------------------------------------------------------------------

export default function ScheduleDatePicker({
  open,
  onClose,
  dates,
  disabledDates,
  setDates,
}: Props) {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    setAnchorEl(open ? wrapperRef.current : null);
  }, [open]);

  const renderPickerDay = (
    date: Date,
    selectedDates: DateType[],
    pickersDayProps: PickersDayProps<any>
  ) => {
    if (!dates) {
      return <PickersDay {...pickersDayProps} />;
    }

    const selected = findDate(dates, date);

    return <PickersDayStyle {...pickersDayProps} disableMargin selected={!!selected} />;
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <RootStyle ref={wrapperRef}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', p: 2 }}>
          <Typography sx={{ flexGrow: 1 }}>{dates?.length} selected</Typography>
          <EventIcon sx={{ ml: 1, color: '#637381' }} />
        </Box>
        {anchorEl && (
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={() => {
              onClose();
              setAnchorEl(null);
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            PaperProps={{ sx: { borderRadius: 0 } }}
          >
            <StaticDatePicker
              displayStaticWrapperAs="desktop"
              label=""
              value={null}
              onChange={(newValue: DateType) => {
                const array = [...(dates ?? [])];
                if (newValue) {
                  const date = startOfDay(newValue);
                  const index = findIndexDate(array, date);
                  const newArray =
                    index >= 0
                      ? array.filter((item) => !isSameDay(item as Date, date))
                      : [...array, date];
                  setDates(newArray as Date[]);
                }
              }}
              renderDay={renderPickerDay}
              renderInput={(params) => <TextField {...params} />}
              inputFormat="dd/MM/yyyy"
              shouldDisableDate={disabledDates}
            />
          </Popover>
        )}
      </RootStyle>
    </LocalizationProvider>
  );
}
