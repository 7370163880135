import * as Yup from 'yup';
import _ from 'lodash';

declare module 'yup' {
  // tslint:disable-next-line
  interface ArraySchema<T> {
    unique(message?: any, mapper?: (value: T, index?: number, list?: T[]) => T[]): ArraySchema<T>;
    uniqueProperty(propertyPath: string, message: string): ArraySchema<T>;
  }
}

Yup.addMethod(Yup.array, 'unique', function (message, mapper = (val: unknown) => val) {
  return this.test('unique', message, (list = []) => {
    const indexNaN = list.map(mapper).findIndex((val: any) => val?.toString() === 'NaN');
    if (list.map(mapper).indexOf(undefined) > -1 || indexNaN > -1) return true;
    return list.length === new Set(list.map(mapper)).size;
  });
});

Yup.addMethod(Yup.array, 'uniqueProperty', function (propertyPath, message) {
  return this.test('unique', '', function (list: any) {
    const errors: any = [];

    list.forEach((item: any, index: any) => {
      const propertyValue = _.get(item, propertyPath);

      if (propertyValue && _.filter(list, [propertyPath, propertyValue]).length > 1) {
        errors.push(
          this.createError({
            path: `${this.path}[${index}].${propertyPath}`,
            message,
          })
        );
      }
    });

    if (!_.isEmpty(errors)) {
      throw new Yup.ValidationError(errors);
    }

    return true;
  });
});
