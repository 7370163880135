import { createContext, ReactNode, useEffect, useReducer } from 'react';
import jwtDecode from 'jwt-decode';
// @types
import { ActionMap, AuthState, AuthUser, JWTContextType } from '_types/auth';
// redux
import { useDispatch } from 'redux/store';
import { getRegionList } from 'redux/slices/run';
import { updateNavConfig } from 'redux/slices/global';
// utils
import {
  // handleRefreshToken,
  isValidToken,
  setSession,
} from 'utils/jwt';
import axios from 'utils/axios';
// services
import * as services from 'services/auth';

// ----------------------------------------------------------------------

enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  Register = 'REGISTER',
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [Types.Login]: {
    user: AuthUser;
  };
  [Types.Logout]: undefined;
  [Types.Register]: {
    user: AuthUser;
  };
};

export type JWTActions = ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    case 'REGISTER':
      return {
        ...state,
        isAuthenticated: false,
        user: action.payload.user,
      };

    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: ReactNode;
};

function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);
  const dispatchStore = useDispatch();
  useEffect(() => {
    const initialize = async () => {
      try {
        const { accessToken, refreshToken } = JSON.parse(
          localStorage.getItem(process.env.REACT_APP_LOCAL_TOKEN!) || '{}'
        );

        if (refreshToken && isValidToken(accessToken)) {
          // let userData = await handleRefreshToken(refreshToken);
          // const { roles } = jwtDecode<{ roles: string[] }>(userData?.token);
          // userData = { ...userData, roles };
          const userData = JSON.parse(localStorage.getItem('userData') || '');
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: true,
              //TODO when refresh token
              user: userData,
            },
          });
          // Get List
          dispatchStore(getRegionList());
          const { roles } = jwtDecode<{ roles: string[] }>(userData?.token);
          if (roles && roles.length > 0) dispatchStore(updateNavConfig(roles[0]));
        } else {
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchStore]);

  const login = async (email: string, password: string) => {
    const response = await services.loginService({
      username: email,
      password,
    });

    if (response) {
      let userData = response as AuthUser;
      if (userData) {
        setSession(userData);
        const { roles } = jwtDecode<{ roles: string[] }>(userData?.token);
        if (roles && roles.length > 0) dispatchStore(updateNavConfig(roles[0]));
        userData = { ...userData, roles };
        localStorage.setItem('userData', JSON.stringify(userData));
      }
      dispatch({
        type: Types.Login,
        payload: {
          user: userData,
        },
      });
      // Get List
      // dispatchStore(getCarrierList());
      dispatchStore(getRegionList());
    }
  };

  const register = async (
    name: string,
    organisationName: string,
    email: string,
    password: string
  ) => {
    const response = await axios.post('/api/auth/signup', {
      name,
      username: email,
      organisationName,
      email,
      password,
    });
    const { user } = response.data;

    dispatch({
      type: Types.Register,
      payload: {
        user,
      },
    });
  };

  const logout = async (email: string) => {
    await services.logoutService({
      username: email,
    });

    setSession({});
    dispatch({ type: Types.Logout });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
