import * as api from 'utils/axios';
import * as endpoints from 'services/run/endpoints';

export const carrierList = () =>
  api.sendGet(endpoints.GET_LIST_CARRIER, {
    headers: { 'Content-Type': 'application/json', accept: 'application/json' },
    data: {},
  });

export const createScheduleRuns = (params: {
  regionId: string;
  runSchedules: {
    deliveryDate: string;
    forecastedCapacity: number;
  }[];
}) => api.sendPut(endpoints.CREATE_SCHEDULE_RUNS, params);

export const getRunDetails = (runId: string) =>
  api.sendGet(`${endpoints.RUN_DETAILS}${runId}`, {
    headers: { 'Content-Type': 'application/json', accept: 'application/json' },
    data: {},
  });

export const getRunDetailByCarrier = (runId: string, carrierId: string) =>
  api.sendGet(endpoints.RUN_DETAIL_BY_CARRIER.replace('{runId}', runId), {
    headers: { 'Content-Type': 'application/json', accept: 'application/json' },
    data: {},
    params: { carrierId },
  });

export const updateRunStatus = (payload: { runId: string; runStatus: string }) =>
  api.sendPatch(endpoints.UPDATE_RUN_STATUS, payload, {
    headers: { 'Content-Type': 'application/json' },
  });

export const toggleRunDetails = (runDetailsId: string, newStatus: string) =>
  api.sendPatch(
    endpoints.TOGGLE_RUN_DETAILS.replace('{runDetailsId}', runDetailsId),
    {
      newStatus,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

export const createTrucks = (params: { runId: string; truckList: any[] }) =>
  api.sendPut(endpoints.TRUCK_BULK, params);

export const updateRunDetails = (
  runId: string,
  payload: {
    deliveryDate?: string | number | Date;
    forecastedCapacity?: number;
  }
) =>
  api.sendPatch(`${endpoints.RUN_DETAILS}${runId}`, payload, {
    headers: { 'Content-Type': 'application/json' },
  });

export const getRegionList = () =>
  api.sendGet(endpoints.REGION_LIST, {
    headers: { 'Content-Type': 'application/json', accept: 'application/json' },
  });

export const getRunList = (regionId: string) => {
  const params = regionId ? `?regionId=${regionId}` : '';
  return api.sendGet(`${endpoints.RUN_LIST}${params}`, {
    headers: { 'Content-Type': 'application/json', accept: 'application/json' },
    data: {},
  });
};

export const getRunManagement = (params?: { regionId: string }) =>
  api.sendPost(endpoints.RUN_MANAGEMENT, params);

export const updateRunDetailsConfirmedTally = (runDetailsId: string, pickedUpTally: number) =>
  api.sendPatch(
    endpoints.UPDATE_RUN_DETAILS_CONFIRMED_TALLY.replace('{runDetailsId}', runDetailsId),
    {
      newNumber: pickedUpTally,
    }
  );

export const updateRunDetailsPickedUpTally = (runDetailsId: string, pickedUpTally: number) =>
  api.sendPatch(
    endpoints.UPDATE_RUN_DETAILS_PICKED_UP_TALLY.replace('{runDetailsId}', runDetailsId),
    {
      newNumber: pickedUpTally,
    }
  );

export const updateEstimatedArrivalTime = (
  runId: string,
  carrierId: string,
  params: {
    estimatedTime: string;
    runDetailIds: string[];
  }
) =>
  api.sendPatch(
    endpoints.UPDATE_ARRIVAL_TIME.replace('{runId}', runId).replace('{carrierId}', carrierId),
    {
      ...params,
    }
  );

export const updateNote = (runId: string, notes: string) =>
  api.sendPatch(endpoints.UPDATE_NOTE.replace('{runId}', runId), {
    notes: notes,
  });

export const getNote = (runId: string) =>
  api.sendGet(endpoints.GET_NOTE.replace('{runId}', runId), {
    headers: { 'Content-Type': 'application/json', accept: 'application/json' },
    data: {},
  });
