import mapboxgl from 'mapbox-gl';
// services
import { getLongLat } from 'services/map';

// @ts-ignore
// eslint-disable-next-line
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

/**
 * Reorder the routes based on waypoint indices in the response.
 * @param routes The original routes array
 * @param optimizeApiResponse The optimized routes that is generated by Mapbox API
 * @returns The array of routes that is reordered
 */
type WaypointType = {
  coordinates: [number, number];
  waypoint_index: number;
  [key: string]: any;
};

type OptimizeApiResponseType = { waypoints: WaypointType[]; [key: string]: any };

export function reorderRoutes(routes: any[], optimizeApiResponse: OptimizeApiResponseType) {
  const { waypoints } = optimizeApiResponse;

  // Get the index of the first and last waypoints to exclude
  const excludeStartIndex = waypoints[0].waypoint_index;
  const excludeEndIndex = waypoints[waypoints.length - 1].waypoint_index;

  const newRoutes: any[] = Array.from({ length: waypoints.length - 2 });

  waypoints.forEach((waypoint, index) => {
    const wpIndex = waypoint.waypoint_index;
    if (wpIndex !== excludeStartIndex && wpIndex !== excludeEndIndex) {
      newRoutes[wpIndex] = routes[index];
    }
  });

  return newRoutes.filter((route) => route !== undefined);
}

/**
  Create a Mapbox LngLat object from longitude and latitude coordinates.
  @param longitude The longitude coordinate
  @param latitude The latitude coordinate
  @returns A Mapbox LngLat object
  @throws Error if longitude or latitude is not a valid number
*/

export function createLngLatObject(longitude: number, latitude: number): mapboxgl.LngLat {
  if (isNaN(longitude) || isNaN(latitude)) {
    throw new Error('Invalid LngLat object');
  }

  return new mapboxgl.LngLat(longitude, latitude);
}

export async function getLatLongByAddress(address: string) {
  const response: any = await getLongLat(address);

  if (response?.features?.length > 0) {
    const coordinates = response.features[0].center;
    return { latitude: coordinates[1], longitude: coordinates[0] };
  } else {
    throw new Error(`Unable to find coordinates for address: ${address}`);
  }
}
