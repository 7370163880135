// ----------------------------------------------------------------------

export const user = [
    'Sydney Huffman',
    'Sydney Huffman',
    'Sydney Huffman',
    'Sydney Huffman',
    'Sydney Huffman',
    'Sydney Huffman',
    'Sydney Huffman',
    'Sydney Huffman',
    'Sydney Huffman',
    'Sydney Huffman',
    'Sydney Huffman',
    'Sydney Huffman',
    'Sydney Huffman',
    'Sydney Huffman',
    'Sydney Huffman',
    'Sydney Huffman',
    'Sydney Huffman',
    'Sydney Huffman',
    'Sydney Huffman',
    'Sydney Huffman',
    'Sydney Huffman',
    'Sydney Huffman',
    'Sydney Huffman',
    'Sydney Huffman',
    'Sydney Huffman',
    'Sydney Huffman',
    'Sydney Huffman',
    'Sydney Huffman',
    'Sydney Huffman',
    'Sydney Huffman',
    'Sydney Huffman',
    'Sydney Huffman',
    'Sydney Huffman',
    'Sydney Huffman',
    'Sydney Huffman',
    'Sydney Huffman',
    'Sydney Huffman',
    'Sydney Huffman',
    'Sydney Huffman',
    'Sydney Huffman',
  ];
  
export const lastActive = [
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
  ];
  
export const dateRegistered = [
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
    'Oct 27, 2021',
  ];
  
export const eventsAssigned = [
    '3',
    '3',
    '3',
    '3',
    '3',
    '3',
    '3',
    '3',
    '3',
    '3',
    '3',
    '3',
    '3',
    '3',
    '3',
    '3',
    '3',
    '3',
    '3',
    '3',
    '3',
    '3',
    '3',
    '3',
    '3',
    '3',
    '3',
    '3',
    '3',
    '3',
    '3',
    '3',
    '3',
    '3',
    '3',
    '3',
    '3',
    '3',
    '3',
    '3',
  ];
  
export const status = [
  'active',
  'active',
  'active',
  'active',
  'active',
  'active',
  'active',
  'active',
  'active',
  'active',
  'active',
  'active',
  'active',
  'active',
  'active',
  'active',
  'active',
  'active',
  'active',
  'active',
  'active',
  'active',
  'active',
  'active',
  'active',
  'active',
  'active',
  'active',
  'active',
  'invited',
  'invited',
  'invited',
  'invited',
  'invited',
  'inactive',
  'inactive',
  'inactive',
  'inactive',
  'inactive',
  'inactive',
];
