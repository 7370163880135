import { createSlice } from '@reduxjs/toolkit';
//
import { dispatch } from '../store';
// _types
import { FarmType } from '_types/farm';
// services
import * as services from 'services/farm';
// ----------------------------------------------------------------------

type InitialState = {
  isLoading: boolean;
  error: Error | string | null;

  //FARM LIST
  isLoadingFarmList: boolean;
  errorFarmList: Error | string | null;
  farmList: FarmType[];

  // UPDATE FARM
  isLoadingUpdateFarm: boolean;
  errorUpdateFarm: Error | string | null;
  isSuccessUpdateFarm: boolean;
};

const initialState: InitialState = {
  isLoading: false,
  error: null,

  //FARM LIST
  isLoadingFarmList: false,
  errorFarmList: null,
  farmList: [],

  // UPDATE FARM
  isLoadingUpdateFarm: false,
  errorUpdateFarm: null,
  isSuccessUpdateFarm: false,
};

const slice = createSlice({
  name: 'farm',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    //FARM LIST
    startLoadingFarmList(state) {
      state.isLoadingFarmList = true;
      state.errorFarmList = null;
      state.farmList = [];
    },
    hasErrorFarmList(state, action) {
      state.isLoadingFarmList = false;
      state.errorFarmList = action.payload;
    },
    updateFarmList(state, action) {
      state.isLoadingFarmList = false;
      state.farmList = action.payload;
    },
    updateEditFarmList(state, action) {
      state.farmList?.map((val) => {
        if (val.farmId === action.payload) {
          return (val.isEdit = true);
        } else {
          return (val.isEdit = false);
        }
      });
    },
    updateCancelEditFarmList(state, action) {
      const farmIndex = state.farmList.findIndex((val) => val.farmId === action.payload);
      state.farmList[farmIndex].isEdit = false;
    },
    updateSaveCarrierFarmList(state, action) {
      const farmIndex = state.farmList.findIndex((val) => val.farmId === action.payload.farmId);
      state.farmList[farmIndex].carrierId = action.payload.carrierId;
      state.farmList[farmIndex].carrierName = action.payload.carrierName;
      state.farmList[farmIndex].isEdit = false;
    },
    // UPDATE FARM
    startLoadingUpdateFarm(state) {
      state.isLoadingUpdateFarm = true;
    },
    hasErrorUpdateFarm(state, action) {
      state.isLoadingUpdateFarm = false;
      state.errorUpdateFarm = action.payload;
    },
    updateIsSuccessUpdateFarm(state, action) {
      state.isSuccessUpdateFarm = action.payload;
    },

    // CLEAN STATE POPUP
    cleanStatePopup(state) {
      state.errorFarmList = null;
      state.errorUpdateFarm = null;
      state.isSuccessUpdateFarm = false;
    },
  },
});

// Reducer
export default slice.reducer;

export const { actions } = slice;

// ----------------------------------------------------------------------

export function getFarmList() {
  return () => {
    dispatch(slice.actions.startLoadingFarmList());
    try {
      services
        .getFarmListService()
        .then((response: any) => {
          dispatch(slice.actions.updateFarmList(response?.data));
        })
        .catch((error: any) => {
          dispatch(slice.actions.hasErrorFarmList(error?.data?.message || error?.data?.error));
        });
    } catch (error) {
      dispatch(slice.actions.hasErrorFarmList(error?.message));
    }
  };
}

// ----------------------------------------------------------------------

export function updateEditFarmList(farmId: string) {
  return () => {
    dispatch(slice.actions.updateEditFarmList(farmId));
  };
}

// ----------------------------------------------------------------------

export function updateCancelEditFarmList(farmId: string) {
  return () => {
    dispatch(slice.actions.updateCancelEditFarmList(farmId));
  };
}

// ----------------------------------------------------------------------

export function updateFarm(farmParams: any[]) {
  return () => {
    dispatch(slice.actions.startLoadingUpdateFarm());
    try {
      services
        .updateFarm(farmParams)
        .then((response: any) => {
          dispatch(getFarmList());
          dispatch(slice.actions.updateIsSuccessUpdateFarm(true));
        })
        .catch((error: any) => {
          dispatch(slice.actions.hasErrorUpdateFarm(error?.data?.message || error?.message));
        });
    } catch (error) {
      dispatch(slice.actions.hasErrorUpdateFarm(error?.message));
    }
  };
}

// ----------------------------------------------------------------------

export function cleanStatePopup() {
  return () => {
    dispatch(slice.actions.cleanStatePopup());
  };
}

// ----------------------------------------------------------------------
