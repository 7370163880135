import { createSlice } from '@reduxjs/toolkit';
//
import { dispatch } from '../store';
// _types
import { FarmType } from '_types/farm';
// services
import * as services from 'services/processor';
// ----------------------------------------------------------------------

type InitialState = {
  isLoading: boolean;
  error: Error | string | null;
  // PROCESSOR LIST
  processorList: any;

  //CARRIER LIST
  isLoadingCarrierList: boolean;
  errorCarrierList: Error | string | null;
  carrierList: any;

  // UPDATE CARRIER
  isLoadingUpdateCarrier: boolean;
  errorUpdateCarrier: Error | string | null;
  isSuccessUpdateCarrier: boolean;
};

const initialState: InitialState = {
  isLoading: false,
  error: null,
  // PROCESSOR LIST
  processorList: [],
  //CARRIER LIST
  isLoadingCarrierList: false,
  errorCarrierList: null,
  carrierList: [],

  // UPDATE CARRIER
  isLoadingUpdateCarrier: false,
  errorUpdateCarrier: null,
  isSuccessUpdateCarrier: false,
};

const slice = createSlice({
  name: 'carrier',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    //CARRIER LIST
    startLoadingCarrierList(state) {
      state.isLoadingCarrierList = true;
      state.errorCarrierList = null;
      state.carrierList = [];
    },
    hasErrorCarrierList(state, action) {
      state.isLoadingCarrierList = false;
      state.errorCarrierList = action.payload;
    },
    updateCarrierList(state, action) {
      state.isLoadingCarrierList = false;
      state.carrierList = action.payload;
    },

    updateProcessorList(state, action) {
      state.isLoading = false;
      state.processorList = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

export const { actions } = slice;

// ----------------------------------------------------------------------

export function getProcessorList() {
  return () => {
    dispatch(slice.actions.startLoading());
    try {
      services
        .getProcessorList()
        .then((response: any) => {
          dispatch(slice.actions.updateProcessorList(response?.data));
        })
        .catch((error) => {
          dispatch(slice.actions.hasError(error?.message));
          throw error;
        });
    } catch (error) {
      dispatch(slice.actions.hasError(error?.message));
    }
  };
}
