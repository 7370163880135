  export const startDate = [
    'Tuesday, Nov 1, 2022',
    'Tuesday, Nov 1, 2022',
    'Tuesday, Nov 1, 2022',
    'Tuesday, Nov 1, 2022',
    'Tuesday, Nov 1, 2022',
    'Tuesday, Nov 1, 2022',
    'Tuesday, Nov 1, 2022',
    'Tuesday, Nov 1, 2022',
    'Tuesday, Nov 1, 2022',
    'Tuesday, Nov 1, 2022',
    'Tuesday, Nov 1, 2022',
  ];
  export const endDate = [
    'Tuesday, Nov 1, 2022',
    'Tuesday, Nov 1, 2022',
    'Tuesday, Nov 1, 2022',
    'Tuesday, Nov 1, 2022',
    'Tuesday, Nov 1, 2022',
    'Tuesday, Nov 1, 2022',
    'Tuesday, Nov 1, 2022',
    'Tuesday, Nov 1, 2022',
    'Tuesday, Nov 1, 2022',
    'Tuesday, Nov 1, 2022',
    'Tuesday, Nov 1, 2022',
  ];
  export const author = [
    'Sydney Huffman',
    'Sydney Huffman',
    'Sydney Huffman',
    'Sydney Huffman',
    'Sydney Huffman',
    'Sydney Huffman',
    'Sydney Huffman',
    'Sydney Huffman',
    'Sydney Huffman',
    'Sydney Huffman',
    'Sydney Huffman',
  ];
  export const eventName = ['Night Lights Sky Lantern Festival - Salt Lake City', 'Night Lights Sky Lantern Festival - Salt Lake City', 'Night Lights Sky Lantern Festival - Salt Lake City', 'Night Lights Sky Lantern Festival - Salt Lake City', 'Night Lights Sky Lantern Festival - Salt Lake City', 'Night Lights Sky Lantern Festival - Salt Lake City', 'Night Lights Sky Lantern Festival - Salt Lake City', 'Night Lights Sky Lantern Festival - Salt Lake City', 'Night Lights Sky Lantern Festival - Salt Lake City', 'Night Lights Sky Lantern Festival - Salt Lake City', 'Night Lights Sky Lantern Festival - Salt Lake City'];
  export const statusEvent = ['Live', 'Live', 'Live', 'Live', 'Live', 'Live', 'Live', 'Live', 'Live', 'Live', 'Live'];
  export const location = [
    'Salt Lake City',
    'Salt Lake City',
    'Salt Lake City',
    'Salt Lake City',
    'Salt Lake City',
    'Salt Lake City',
    'Salt Lake City',
    'Salt Lake City',
    'Salt Lake City',
    'Salt Lake City',
    'Salt Lake City',
  ];
  export const attendees = [
    '12,345/ 30,000',
    '12,345/ 30,000',
    '12,345/ 30,000',
    '12,345/ 30,000',
    '12,345/ 30,000',
    '12,345/ 30,000',
    '12,345/ 30,000',
    '12,345/ 30,000',
    '12,345/ 30,000',
    '12,345/ 30,000',
    '12,345/ 30,000',
  ];
  export const waitlist = [true, true, true, true, true, true, true, true, true, true, true];
