import jwtDecode from 'jwt-decode';
// routes
import { PATH_PAGE } from 'routes/paths';
// utils
import axios from 'utils/axios';
// services
import * as services from 'services/auth';
// ----------------------------------------------------------------------

const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode<{ exp: number }>(accessToken);

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const handleTokenExpired = (exp: number, refreshToken: string) => {
  let expiredTimer;

  const currentTime = Date.now();

  // Time left 10s before the token expires
  const timeLeft = exp * 1000 - currentTime - 10000;

  clearTimeout(expiredTimer);

  let timeLeftTemp = timeLeft;
  if (timeLeft > 2147483647 || timeLeft < 0) {
    timeLeftTemp = 2147483647;
  }

  expiredTimer = setTimeout(() => {
    handleRefreshToken(refreshToken);
  }, timeLeftTemp);
};

const setSession = (authData: Record<string, any>) => {
  const { token: accessToken, refreshToken } = authData;
  if (accessToken) {
    localStorage.setItem(
      process.env.REACT_APP_LOCAL_TOKEN!,
      JSON.stringify({ accessToken, refreshToken })
    );

    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    // This function below will handle when token is expired
    const { exp, email } = jwtDecode<{ exp: number; email: string }>(accessToken); // ~5 days by minimals server
    localStorage.setItem('username', email);
    handleTokenExpired(exp, refreshToken);
  } else {
    localStorage.removeItem(process.env.REACT_APP_LOCAL_TOKEN!);
    delete axios.defaults.headers.common.Authorization;
  }
};

const handleRefreshToken = async (refreshToken: string) => {
  try {
    const response: any = await services.refreshtokenService({
      refreshToken,
    });
    if (response.status === 200) {
      setSession(response.data);
    } else {
      setSession({});
      window.location.href = PATH_PAGE.login;
    }
    return response.data;
  } catch (error) {
    setSession({});
    window.location.href = PATH_PAGE.login;
  }
};

export { isValidToken, setSession, handleRefreshToken };
