export const STATUS_RUN = {
  UPCOMING: 'Upcoming',
  SUBMISSION_OPEN: 'Submission Open',
  REVIEW: 'Review',
  ROUTE_MANAGEMENT: 'Route Management',
  ROUTE: 'Route',
  ROUTE_CONFIRMED: 'Route Confirmed',
  ROUTE_PLANNING: 'Route Planning',
  DELIVERY_DAY: 'Delivery Day',
  DONE: 'Done',
  CANCELLED: 'Cancelled',
};

// TRUCK
export const TRUCK_STATUS = {
  NEW: 'NEW',
  COMPLETE: 'COMPLETE',
};

export const PERMISSION = {
  ROLE_CARRIER_ADMIN: 'ROLE_CARRIER_ADMIN',
  ROLE_DRIVER: 'ROLE_DRIVER',
  ROLE_PROCESSOR_ADMIN: 'ROLE_PROCESSOR_ADMIN',
};

export const PROCESSOR_STATUS = {
  EXISTED: 'EXISTED',
  SUCCESS: 'SUCCESS',
};
