// ----------------------------------------------------------------------

// function path(root: string, sublink: string) {
//   return `${root}${sublink}`;
// }

export const PATH_PAGE = {
  //auth
  login: '/login',
  //error
  page403: '/403',
  page404: '/404',
  page500: '/500',
  //main
  dashboard: '/dashboard',
  runManagement: '/run-management',
  farmManagement: '/farm-management',
  processorManagement: '/processor-management',
  runDetails: '/run-management/:runId/edit',
  routeManagement: '/run-management/:runId/:carrierId/route-management/edit',
  routeConfirmed: '/run-management/:runId/:carrierId/route-confirmed/edit',
};
