import { ReactNode } from 'react';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Select, SelectProps } from '@mui/material';

// ----------------------------------------------------------------------

type IProps = {
  name: string;
  children: ReactNode;
};

type Props = IProps & SelectProps;

export default function RHFCustomSelect({ name, children, ...other }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Select {...field} fullWidth error={!!error} {...other}>
          {children}
        </Select>
      )}
    />
  );
}
