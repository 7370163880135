import { createSlice, Dispatch } from '@reduxjs/toolkit';
// redux
import { dispatch } from 'redux/store';
// services
import * as services from 'services/run';
// @types
import { CarrierType, FarmType, RunType, RunItemType, RunDetailType, RegionType } from '_types/run';
// _mock_
import { _farms_tallyResponses, _farms_nonresponsed, _farms_removedFromRun } from '_mock/_farms';

// ----------------------------------------------------------------------

type InitialState = {
  isLoading: boolean;
  error: Error | string | null;
  // REGION
  isLoadingRegionList: boolean;
  errorRegionList: Error | string | null;
  regionList: RegionType[];
  filterRegion: string;

  // FARM
  isLoadingFarmList: boolean;
  errorFarmList: Error | string | null;
  farms: Record<string, FarmType[]>;
  selectedRun: RunItemType | null;

  //RUN DETAILS
  isLoadingRunDetails: boolean;
  errorRunDetails: Error | string | null;
  runDetails: RunDetailType | null;

  //RUN DETAILS BY CARRIER
  isLoadingRunDetailsCarrier: boolean;
  errorRunDetailsCarrier: Error | string | null;
  runDetailsCarrier: RunDetailType | null;

  runDetailsEditingStatus: {
    showWarning: boolean;
    rowIds: string[];
  };

  //RUN LIST
  isLoadingRunList: boolean;
  errorRunList: Error | string | null;
  runList: RunType[] | [];

  //RUN MANAGEMENT
  isLoadingRunManagement: boolean;
  errorRunManagement: Error | string | null;
  runManagement: null | Record<string, any>;

  //CARRIER LIST
  carrierList: CarrierType[] | [];
  isLoadingCarrierList: boolean;
  errorCarrierList: Error | string | null;

  //TOGGLE RUN DETAILS
  isLoadingToggleRunDetails: boolean;
  errorToggleRunDetails: Error | string | null;
  messageToggleRunDetailsSuccess: string;

  // IS OPEN CREATE RUN SCHEDULE
  isScheduleOpen: boolean;
};

const initialState: InitialState = {
  isLoading: false,
  error: null,
  // REGION
  isLoadingRegionList: false,
  errorRegionList: null,
  regionList: [],
  filterRegion: '',

  // FARM
  isLoadingFarmList: false,
  errorFarmList: null,
  farms: {
    tallyResponses: _farms_tallyResponses,
    nonResponsed: _farms_nonresponsed,
    removedFromRun: _farms_removedFromRun,
    lateResponseItems: [],
    zeroTallyResponseItem: [],
  },
  selectedRun: null,

  //RUN DETAILS
  isLoadingRunDetails: false,
  errorRunDetails: null,
  runDetails: null,

  //RUN DETAILS BY CARRIER
  isLoadingRunDetailsCarrier: false,
  errorRunDetailsCarrier: null,
  runDetailsCarrier: null,
  runDetailsEditingStatus: { showWarning: false, rowIds: [] },

  //RUN LIST
  isLoadingRunList: false,
  errorRunList: null,
  runList: [],

  //RUN MANAGEMENT
  isLoadingRunManagement: false,
  errorRunManagement: null,
  runManagement: null,

  //CARRIER LIST
  carrierList: [],
  isLoadingCarrierList: false,
  errorCarrierList: null,

  //TOGGLE RUN DETAILS
  isLoadingToggleRunDetails: false,
  errorToggleRunDetails: null,
  messageToggleRunDetailsSuccess: '',

  // IS OPEN CREATE RUN SCHEDULE
  isScheduleOpen: true,
};

const slice = createSlice({
  name: 'run',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    startLoadingFarmList(state) {
      state.isLoadingFarmList = true;
    },

    hasErrorFarmList(state, action) {
      state.isLoadingFarmList = false;
      state.errorFarmList = action.payload;
    },

    setSelectedRun(state, action) {
      state.selectedRun = action.payload;
    },

    updateFarms(state, action) {
      state.isLoadingFarmList = false;
      const { data, type } = action.payload;
      state.farms[type] = data;
    },
    // UPDATE RUN LIST
    startLoadingRunList(state) {
      state.isLoadingRunList = true;
      state.errorRunList = null;
      state.runList = [];
    },

    hasErrorRunList(state, action) {
      state.isLoadingRunList = false;
      state.errorRunList = action.payload;
    },
    updateRunList(state, action) {
      state.isLoadingRunList = false;
      state.runList = action.payload;
    },
    // UPDATE RUN MANAGEMENT
    startLoadingRunManagement(state) {
      state.isLoadingRunManagement = true;
      state.errorRunManagement = null;
      state.runManagement = null;
    },

    hasErrorRunManagement(state, action) {
      state.isLoadingRunManagement = false;
      state.errorRunManagement = action.payload;
    },
    updateRunManagement(state, action) {
      state.isLoadingRunManagement = false;
      state.runManagement = action.payload;
    },
    // UPDATE CARRIER LIST
    startLoadingCarrierList(state) {
      state.isLoadingCarrierList = true;
      state.errorCarrierList = null;
      state.carrierList = [];
    },

    hasErrorCarrierList(state, action) {
      state.isLoadingCarrierList = false;
      state.errorCarrierList = action.payload;
      state.carrierList = [];
    },

    updateCarrierList(state, action) {
      state.isLoadingCarrierList = false;
      state.carrierList = action.payload;
    },

    // RUN DETAILS
    startLoadingRunDetails(state) {
      state.isLoadingRunDetails = true;
      state.errorRunDetails = null;
      state.runDetails = null;

      state.farms.tallyResponses = [];
      state.farms.nonResponsed = [];
      state.farms.removedFromRun = [];
      state.farms.lateResponseItems = [];
      state.farms.zeroTallyResponseItem = [];
    },

    hasErrorRunDetails(state, action) {
      state.isLoadingRunDetails = false;
      state.errorRunDetails = action.payload;
      state.runDetails = null;

      state.farms.tallyResponses = [];
      state.farms.nonResponsed = [];
      state.farms.removedFromRun = [];
      state.farms.lateResponseItems = [];
      state.farms.zeroTallyResponseItem = [];
    },

    setRunDetails(state, action) {
      state.isLoadingRunDetails = false;

      state.runDetails = action.payload;
      state.farms.tallyResponses = action.payload?.zeroTallyResponseItem
        ? [...action.payload?.responseItems, ...action.payload?.zeroTallyResponseItem]
        : action.payload?.responseItems;
      state.farms.nonResponsed = action.payload?.noResponseItems;
      state.farms.removedFromRun = action.payload?.cancelledItems;
      state.farms.lateResponseItems = action.payload?.lateResponseItems;
      state.farms.zeroTallyResponseItem = action.payload?.zeroTallyResponseItem;
    },

    // RUN DETAILS CARRIER
    startLoadingRunDetailsCarrier(state) {
      state.isLoadingRunDetailsCarrier = true;
      state.errorRunDetailsCarrier = null;
      state.runDetailsCarrier = null;
    },

    hasErrorRunDetailsCarrier(state, action) {
      state.isLoadingRunDetailsCarrier = false;
      state.errorRunDetailsCarrier = action.payload;
      state.runDetailsCarrier = null;
    },

    setRunDetailsCarrier(state, action) {
      state.isLoadingRunDetailsCarrier = false;
      state.runDetailsCarrier = action.payload;
    },

    updateRunDetailsEditingStatus: (state, action) => {
      const { runDetailsId, showWarning } = action.payload;
      if (runDetailsId) {
        const existingIndex = state.runDetailsEditingStatus.rowIds.indexOf(runDetailsId);

        if (existingIndex !== -1) {
          state.runDetailsEditingStatus.rowIds.splice(existingIndex, 1);
        } else {
          state.runDetailsEditingStatus.rowIds.push(runDetailsId);
        }

        if (
          state.runDetailsEditingStatus.rowIds.length === 0 &&
          state.runDetailsEditingStatus.showWarning
        ) {
          state.runDetailsEditingStatus.showWarning = false;
        }
      }

      if (showWarning !== undefined) {
        state.runDetailsEditingStatus.showWarning = showWarning;
      }
    },

    resetRunDetailsEditingStatus: (state) => {
      state.runDetailsEditingStatus = initialState.runDetailsEditingStatus;
    },

    //TOGGLE RUN DETAILS
    startLoadingToggleRunDetails(state) {
      state.isLoadingToggleRunDetails = true;
      state.errorToggleRunDetails = null;
      state.messageToggleRunDetailsSuccess = '';
    },
    hasErrorToggleRunDetails(state, action) {
      state.isLoadingToggleRunDetails = false;
      state.errorToggleRunDetails = action.payload;
    },
    updateToggleRunDetailsSuccess(state, action) {
      state.isLoadingToggleRunDetails = false;
      state.messageToggleRunDetailsSuccess = action.payload;
    },

    // REGION
    startLoadingRegionList(state) {
      state.isLoadingRegionList = true;
    },

    hasErrorRegionList(state, action) {
      state.isLoadingRegionList = false;
      state.errorRegionList = action.payload;
    },

    setRegionList(state, action) {
      state.regionList = action.payload || [];
      state.isLoadingRegionList = false;
    },
    updateFilterRegion(state, action) {
      state.filterRegion = action.payload;
    },

    updateScheduleOpen(state, action) {
      state.isScheduleOpen = action.payload;
    },

    // RUN DETAIL: UPDATE CONFIRMED TALLY
    updateRunDetailsTally(state, action) {
      const { runDetailsId, tallyNumber, tallyProperty } = action.payload;
      const runDetailsIndex = state.farms.tallyResponses.findIndex(
        (item) => item.id === runDetailsId
      );

      if (runDetailsIndex !== -1) {
        const updatedRunDetails = {
          ...state.farms.tallyResponses[runDetailsIndex],
          [tallyProperty]: tallyNumber,
        };

        state.farms.tallyResponses[runDetailsIndex] = updatedRunDetails;
      }
    },

    // RUN DETAIL: UPDATE TIME
    updateRunDetailsTallyTime(state, action) {
      state.farms.tallyResponses = action.payload[0]?.routes;
    },
  },
});

// Reducer
export default slice.reducer;

export const { actions } = slice;

// ----------------------------------------------------------------------

export function setSelectedRun(run: RunItemType) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.setSelectedRun(run));
  };
}

// ----------------------------------------------------------------------

export function updateFarms(data: FarmType[], type: string) {
  return () => {
    dispatch(slice.actions.updateFarms({ data, type }));
  };
}

// ----------------------------------------------------------------------

export function getCarrierList() {
  return () => {
    dispatch(slice.actions.startLoadingCarrierList());
    try {
      services
        .carrierList()
        .then((response: any) => {
          dispatch(slice.actions.updateCarrierList(response?.data));
        })
        .catch((error: any) => {
          dispatch(slice.actions.hasErrorCarrierList(error));
        });
    } catch (error) {
      dispatch(slice.actions.hasErrorCarrierList(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getRunDetails(runId: string) {
  return () => {
    dispatch(slice.actions.startLoadingRunDetails());
    try {
      services
        .getRunDetails(runId)
        .then((response: any) => {
          dispatch(slice.actions.setRunDetails(response?.data));
        })
        .catch((error: any) => {
          dispatch(slice.actions.hasErrorRunDetails(error?.data?.message || error?.data?.error));
        });
    } catch (error: any) {
      dispatch(slice.actions.hasErrorRunDetails(error?.message));
    }
  };
}

// ----------------------------------------------------------------------

export function getRunDetailByCarrier(runId: string, carrierId: string) {
  return () => {
    dispatch(slice.actions.startLoadingRunDetailsCarrier());
    try {
      services
        .getRunDetailByCarrier(runId, carrierId)
        .then((response: any) => {
          dispatch(slice.actions.setRunDetailsCarrier(response?.data));
        })
        .catch((error: any) => {
          dispatch(slice.actions.hasErrorRunDetailsCarrier(error?.data?.message || error?.message));
        });
    } catch (error: any) {
      dispatch(slice.actions.hasErrorRunDetailsCarrier(error?.message));
    }
  };
}

// ----------------------------------------------------------------------

export function updateRunStatus(runId: string, runStatus: string) {
  // eslint-disable-next-line arrow-body-style
  return async () => {
    return services
      .updateRunStatus({ runId, runStatus })
      .then((response: any) => {
        if (response) {
          dispatch(getRunDetails(runId));
          return response?.data;
        }
        return response?.data;
      })
      .catch((error: any) => {
        dispatch(slice.actions.hasErrorRunDetails(error?.message));
        throw error;
      });
  };
}

// ----------------------------------------------------------------------

export function toggleRunDetails(runDetailsId: string, runId: string, newStatus: string) {
  return () => {
    dispatch(slice.actions.startLoadingToggleRunDetails());
    try {
      services
        .toggleRunDetails(runDetailsId, newStatus)
        .then((response: any) => {
          if (response) {
            dispatch(slice.actions.updateToggleRunDetailsSuccess(response?.message));
            dispatch(getRunDetails(runId));
          }
        })
        .catch((error: any) => {
          dispatch(slice.actions.hasErrorToggleRunDetails(error?.message));
        });
    } catch (error) {
      dispatch(slice.actions.hasErrorToggleRunDetails(error?.message));
    }
  };
}

// ----------------------------------------------------------------------

export function updateToggleRunDetailsSuccess(message: string) {
  return () => {
    dispatch(slice.actions.updateToggleRunDetailsSuccess(message));
  };
}

// ----------------------------------------------------------------------

export function updateHasErrorRunDetails(message: string) {
  return () => {
    dispatch(slice.actions.hasErrorRunDetails(message));
  };
}

// ----------------------------------------------------------------------

export function updateHasErrorToggleRunDetails(message: string) {
  return () => {
    dispatch(slice.actions.hasErrorToggleRunDetails(message));
  };
}

// ----------------------------------------------------------------------

export function updateScheduleOpen(isOpen: boolean) {
  return () => {
    dispatch(slice.actions.updateScheduleOpen(isOpen));
  };
}

// ----------------------------------------------------------------------

export function getRegionList() {
  return () => {
    dispatch(slice.actions.startLoadingRegionList());
    try {
      services
        .getRegionList()
        .then((response: any) => {
          dispatch(slice.actions.setRegionList(response?.data));
        })
        .catch((error) => {
          dispatch(slice.actions.hasErrorRunList(error?.message));
          throw error;
        });
    } catch (error) {
      dispatch(slice.actions.hasErrorRunList(error?.message));
    }
  };
}

// ----------------------------------------------------------------------

export function getRunList(regionId?: string) {
  const id = regionId || '';
  return () => {
    dispatch(slice.actions.startLoadingRunList());
    return services
      .getRunList(id)
      .then((response: any) => {
        dispatch(slice.actions.updateRunList(response?.data));
        return response?.data;
      })
      .catch((error: any) => {
        dispatch(slice.actions.hasErrorRunList(error?.message));
        throw error;
      });
  };
}
// ----------------------------------------------------------------------

export function getRunManagement(regionId: string) {
  return () => {
    dispatch(slice.actions.startLoadingRunManagement());
    try {
      return services
        .getRunManagement({ regionId })
        .then((response: any) => {
          dispatch(slice.actions.updateRunManagement(response?.data));
          return response?.data;
        })
        .catch((error: any) => {
          dispatch(slice.actions.hasErrorRunManagement(error?.message));
          throw error;
        });
    } catch (error) {
      dispatch(slice.actions.hasErrorRunManagement(error?.message));
      throw error;
    }
  };
}
// ----------------------------------------------------------------------

export function updateFilterRegion(filterRegion: string) {
  return () => {
    dispatch(slice.actions.updateFilterRegion(filterRegion));
  };
}

// ----------------------------------------------------------------------

export function updateRunDetailsConfirmedTally(runDetailsId: string, tallyNumber: number) {
  return () =>
    dispatch(
      slice.actions.updateRunDetailsTally({
        runDetailsId,
        tallyNumber,
        tallyProperty: 'submittedTally',
      })
    );
}

// ----------------------------------------------------------------------

export function updateRunDetailsPickedUpTally(
  runDetailsId: string,
  tallyNumber: number,
  estimatedArrivalTime?: string
) {
  return () =>
    dispatch(
      slice.actions.updateRunDetailsTally({
        runDetailsId,
        tallyNumber,
        tallyProperty: 'actualPickedUpTally',
        estimatedArrivalTime,
      })
    );
}

// ----------------------------------------------------------------------

export function updateEstimatedTimeArrival(
  runId: string,
  carrierId: string,
  params: {
    estimatedTime: string;
    runDetailIds: string[];
  }
) {
  return () => {
    dispatch(slice.actions.startLoading());
    try {
      return services
        .updateEstimatedArrivalTime(runId, carrierId, params)
        .then((response: any) => response?.data)
        .catch((error: any) => {
          dispatch(slice.actions.hasError(error?.message));
          throw error;
        });
    } catch (error) {
      dispatch(slice.actions.hasError(error?.message));
      throw error;
    }
  };
}
// ----------------------------------------------------------------------

export function updateRunDetailsTallyTime(tallyResponses: any) {
  return () => dispatch(slice.actions.updateRunDetailsTallyTime(tallyResponses));
}
// ----------------------------------------------------------------------
