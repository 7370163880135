// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import {
  FormControlLabel,
  InputLabel,
  TextField,
  TextFieldProps,
  Typography,
  styled,
} from '@mui/material';

// ----------------------------------------------------------------------

const FormControlLabelStyle = styled(FormControlLabel)(({ theme }) => ({
  alignItems: 'flex-start',
  margin: 0,
  width: '100%',
}));
// ----------------------------------------------------------------------

type IProps = {
  name: string;
  errorAfterSubmit?: boolean;
  helperTextAfterSubmit?: string;
};

type Props = IProps & TextFieldProps;

export default function RHFTextField({
  name,
  errorAfterSubmit,
  helperTextAfterSubmit,
  label,
  ...other
}: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field, fieldState: { error } }) => (
        <FormControlLabelStyle
          control={
            <TextField
              {...field}
              fullWidth
              // error={!!error || !!errorAfterSubmit}
              helperText={helperTextAfterSubmit}
              {...other}
            />
          }
          label={
            <InputLabel id="region-label">
              <Typography sx={{ fontWeight: 500, fontSize: 13, color: '#00344B' }}>
                {label}
              </Typography>
            </InputLabel>
          }
          labelPlacement="top"
        />
      )}
    />
  );
}
