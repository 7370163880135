export const CREATE_SCHEDULE_RUNS = '/api/run/';
export const GET_LIST_CARRIER = '/api/carrier/';
export const FARM_LIST = '/api/property/';
export const RUN_DETAILS = '/api/run/';
export const UPDATE_RUN_DETAILS_CONFIRMED_TALLY =
  '/api/run-details/{runDetailsId}/update-confirmed-tally';
export const UPDATE_RUN_DETAILS_PICKED_UP_TALLY =
  '/api/run-details/{runDetailsId}/update-pickup-tally';
export const RUN_DETAIL_BY_CARRIER = '/api/run/{runId}/by-carrier';
export const UPDATE_RUN_STATUS = '/api/run/change-status';
export const TOGGLE_RUN_DETAILS = '/api/run-details/{runDetailsId}';
export const REGION_LIST = '/api/metadata/regions/';
export const RUN_LIST = '/api/run';
export const RUN_MANAGEMENT = '/api/run/run-management';

//TRUCK
export const TRUCK_BULK = '/api/truck/bulk';

// ARRIVAL TIME
export const UPDATE_ARRIVAL_TIME = '/api/run/{runId}/{carrierId}/update-arrival-time';

// NOTE
export const UPDATE_NOTE = '/api/run/{runId}/update-notes';
export const GET_NOTE = '/api/run/{runId}/notes';
