// hooks
import useAuth from 'hooks/useAuth';
// constants
import { PERMISSION } from 'utils/constants';

// ----------------------------------------------------------------------

export function useCheckPermission(role: string) {
  const { user } = useAuth();

  return user && user.roles && user.roles.includes(role);
}
// ----------------------------------------------------------------------

export function useIsCarrierAdmin() {
  const { user } = useAuth();

  return user && user.roles && user.roles.includes(PERMISSION.ROLE_CARRIER_ADMIN);
}

// ----------------------------------------------------------------------

export const useHasPermission = (requiredRoles: string[]) => {
  const { user } = useAuth();
  return user && user.roles && user.roles.some((role: string) => requiredRoles.includes(role));
};

// ----------------------------------------------------------------------

export const useIsTransportUser = () => {
  const { user } = useAuth();
  const transportRoles = [PERMISSION.ROLE_CARRIER_ADMIN, PERMISSION.ROLE_DRIVER];
  return user && user.roles && user.roles.some((role: string) => transportRoles.includes(role));
};

// ----------------------------------------------------------------------

export const useIsDriver = () => {
  const { user } = useAuth();
  return user && user.roles && user.roles.some((role: string) => role === PERMISSION.ROLE_DRIVER);
};

// ----------------------------------------------------------------------

export const useIsProcessorAdmin = () => {
  const { user } = useAuth();
  return (
    user &&
    user.roles &&
    user.roles.some((role: string) => role === PERMISSION.ROLE_PROCESSOR_ADMIN)
  );
};
