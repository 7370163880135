// utils
import createAvatar from 'utils/createAvatar';
// components
import Avatar, { Props as AvatarProps } from 'components/Avatar';
// ----------------------------------------------------------------------

export interface Props extends AvatarProps {
  avatarUrl?: string;
  displayName: string;
  isThumbnail?: boolean;
}

export default function ProfilePicture({
  isThumbnail = false,
  avatarUrl,
  displayName,
  ...other
}: Props) {
  return (
    <Avatar
      src={avatarUrl}
      alt={displayName}
      sx={{ height: 30, width: 30, background: '#EAC353', color: '#02344A', fontSize: 16 }}
      {...other}
    >
      {(displayName && createAvatar(displayName)?.name) || null}
    </Avatar>
  );
}
