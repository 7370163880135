import { isWeekend, startOfDay, addDays, addBusinessDays } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
// utils
import { isInTimeZone } from './date';
// config
import { TIME_ZONE as nzTimeZone, ALLOW_WEEKENDS } from 'config';

export const getInitialDate = (): Date => {
  const date = new Date();
  const currentDate = !isInTimeZone(date, nzTimeZone) ? utcToZonedTime(date, nzTimeZone) : date;
  const currentHour = currentDate.getHours();
  const minDate = currentHour < 10 ? 2 : 3;

  const initialDate = ALLOW_WEEKENDS
    ? addDays(startOfDay(currentDate), minDate)
    : addBusinessDays(startOfDay(currentDate), minDate);

  return initialDate;
};

/**
 *
 * @param date the date to check
 * @param initialDate the initial selectable date
 */
export const validateDisabledDates = (date: Date, initialDate?: Date): boolean =>
  !ALLOW_WEEKENDS
    ? initialDate
      ? isWeekend(date) || date < initialDate
      : isWeekend(date)
    : initialDate
    ? date < initialDate
    : false;
