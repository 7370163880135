import * as api from 'utils/axios';
import * as endpoints from 'services/farm/endpoints';

export const getFarmListService = () =>
  api.sendGet(endpoints.FARM_LIST, {
    headers: { 'Content-Type': 'application/json', accept: 'application/json' },
    data: {},
  });

export const sendSmsCarrier = (carrierId: string, params?: any) =>
  api.sendPost(endpoints.SEND_SMS_CARRIER.replace('{carrierId}', carrierId), params);

export const sendSmsFarm = (propertyId: string, params?: any) =>
  api.sendPost(endpoints.SEND_SMS_FARM.replace('{propertyId}', propertyId), params);

export const updateFarm = (params?: any) =>
  api.sendPatch(endpoints.UPDATE_FARM, params, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const sendSmsMultiFarm = (params?: any) =>
  api.sendPost(endpoints.SEND_SMS_MULTI_FARM, params);
