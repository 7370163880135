import { useState, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
// redux
import { updateScheduleOpen } from 'redux/slices/run';
import { useDispatch } from 'redux/store';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, Button, IconButton } from '@mui/material';
// hooks
import { useIsTransportUser, useIsProcessorAdmin } from 'hooks/useCheckPermission';
import useResponsive from 'hooks/useResponsive';
// utils
import cssStyles from 'utils/cssStyles';
// config
import { NAVBAR, HEADER } from 'config';
// components
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import AccountPopover from './AccountPopover';
import Iconify from 'components/Iconify';
// routes
import { PATH_PAGE } from 'routes/paths';
// sections
import { Schedule } from 'sections/run/schedule';

// ----------------------------------------------------------------------

const RootStyle = styled(AppBar)(({ theme }) => ({
  ...cssStyles(theme).bgBlur(),
  boxShadow: 'none',
  height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
  width: `calc(100% - ${NAVBAR.BASE_WIDTH}px)`,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
}));

const BreadcrumbStyle = styled(HeaderBreadcrumbs)(({ theme }) => ({
  marginBottom: '0',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '.MuiBox-root': {
    marginBottom: 0,
  },
  a: {
    color: 'rgba(49, 49, 49, 0.7) !important',
  },
  '.breadcrumb-currentLink': {
    color: '#000 !important',
  },
  'a, .breadcrumb-currentLink': {
    fontWeight: 500,
    fontSize: '14px',
    [theme.breakpoints.up('md')]: {
      overflow: 'inherit',
    },
  },
}));

const BREADCRUMB_ROUTES = [
  { slug: 'run-management', name: 'Run Management', path: PATH_PAGE.runManagement },
];

// ----------------------------------------------------------------------

export type Props = {
  breadcrumbs?: { name: string; href?: string }[];
  onOpenNav?: VoidFunction;
};

// ----------------------------------------------------------------------

export default function DashboardHeader({ breadcrumbs = [], onOpenNav }: Props) {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const [isShowCreateButton, setIsShowCreateButton] = useState(true);
  const [isShowCreateProcessorButton, setIsShowCreateProcessorButton] = useState(true);

  const isDesktop = useResponsive('up', 'lg');

  const defaultBreadcrumb = BREADCRUMB_ROUTES.find((b) => pathname.includes(b.path));
  const isBreadcrumbPath = (slug: string) => new RegExp(`^/${slug}.*/edit.*`).test(pathname);
  const isBreadcrumb = defaultBreadcrumb?.path && isBreadcrumbPath(defaultBreadcrumb?.slug);

  const headingPage: string = useMemo(() => {
    setIsShowCreateButton(true);

    if (pathname.includes(PATH_PAGE.runManagement)) return 'Run Management';
    if (pathname.includes(PATH_PAGE.farmManagement)) {
      setIsShowCreateButton(false);
      setIsShowCreateProcessorButton(false);
      return 'Farm Management';
    }
    if (pathname.includes(PATH_PAGE.processorManagement)) {
      setIsShowCreateButton(false);
      setIsShowCreateProcessorButton(true);

      return 'Processor Management';
    }

    return 'Overview';
  }, [pathname]);

  const isTransportUser = useIsTransportUser();
  const isProcessorAdmin = useIsProcessorAdmin();

  return (
    <>
      {((!isBreadcrumb && breadcrumbs.length === 0) ||
        (isBreadcrumb && breadcrumbs.length > 0)) && (
        <RootStyle sx={{ width: `calc(100% - ${isDesktop ? NAVBAR.BASE_WIDTH : 0}px)` }}>
          <Toolbar
            sx={{
              minHeight: '100% !important',
              padding: '5px 20px',
              height: 50,
            }}
          >
            {!isDesktop && (
              <IconButton onClick={onOpenNav} sx={{ mr: 1, color: 'text.primary' }}>
                <Iconify icon="eva:menu-2-fill" />
              </IconButton>
            )}

            {/* Breadcrumb */}
            <BreadcrumbStyle
              heading={!isBreadcrumb ? headingPage : ''}
              links={isBreadcrumb ? breadcrumbs : []}
            />

            {/* <Searchbar /> */}
            <Box sx={{ flexGrow: 1 }} />

            <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
              {isProcessorAdmin || isTransportUser ? null : (
                <>
                  {isShowCreateButton ? (
                    <Button
                      variant="contained"
                      onClick={() => {
                        setOpen(true);
                        dispatch(updateScheduleOpen(true));
                      }}
                    >
                      Create
                    </Button>
                  ) : isShowCreateProcessorButton ? (
                    //TODO : waiting ticket inprogress
                    // <Button variant="contained" onClick={() => {}}>
                    //   Create Processor
                    // </Button>
                    <></>
                  ) : null}
                </>
              )}
              <Schedule open={open} setOpen={setOpen} />
              <AccountPopover />
            </Stack>
          </Toolbar>
        </RootStyle>
      )}
    </>
  );
}
