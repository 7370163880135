// routes
import { PATH_PAGE } from 'routes/paths';

// ----------------------------------------------------------------------

const navConfig = [
  // MAIN
  // ----------------------------------------------------------------------
  {
    subheader: 'MyLiveStock',
    items: [
      {
        title: 'Dashboard',
        path: PATH_PAGE.dashboard,
      },
      { title: 'Run Management', path: PATH_PAGE.runManagement },
      { title: 'Farm Management', path: PATH_PAGE.farmManagement },
      { title: 'Processor Management', path: PATH_PAGE.processorManagement },
    ],
  },
];

const navConfigCarrier = [
  // MAIN
  // ----------------------------------------------------------------------
  {
    subheader: 'MyLiveStock',
    items: [{ title: 'Run Management', path: PATH_PAGE.runManagement }],
  },
];

const navConfigProcessor = [
  // MAIN
  // ----------------------------------------------------------------------
  {
    subheader: 'MyLiveStock',
    items: [
      {
        title: 'Dashboard',
        path: PATH_PAGE.dashboard,
      },
      { title: 'Run Management', path: PATH_PAGE.runManagement },
    ],
  },
];

export { navConfig, navConfigCarrier, navConfigProcessor };
