import { getTimezoneOffset } from 'date-fns-tz';

export function isInTimeZone(date: Date, timezone: string): boolean {
  if (typeof Intl === 'object' && typeof Intl.DateTimeFormat === 'function') {
    return Intl.DateTimeFormat().resolvedOptions().timeZone === timezone;
  } else {
    const offset = date.getTimezoneOffset() * 60000;
    const timeZoneOffset = getTimezoneOffset(timezone, date);
    return offset === -1 * timeZoneOffset;
  }
}

export const findDate = (dates: Date[] | undefined, date: Date | undefined): Date | undefined =>
  dates?.find((item) => item?.getTime() === date?.getTime());

export const findIndexDate = (dates: Date[] | undefined, date: Date | undefined): number =>
  dates?.findIndex((item) => item?.getTime() === date?.getTime()) ?? -1;
