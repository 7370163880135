import React from 'react';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField, TextFieldProps } from '@mui/material';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers';

// ----------------------------------------------------------------------

interface Props extends Omit<DatePickerProps<Date, Date>, 'value' | 'renderInput' | 'onChange'> {
  name: string;
  label?: string;
  inputFormat: string;
  textPlaceholder?: string;
  textFieldProps?: Omit<TextFieldProps, 'label' | 'error' | 'onChange' | 'value' | 'inputRef'>;
  onChange?: (newValue: any) => void;
}

export default function RHFDatePicker({
  name,
  label,
  inputFormat = 'dd/MM/yyyy hh:mm a',
  textPlaceholder = inputFormat,
  textFieldProps,
  onChange,
  ...other
}: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <DatePicker
          {...field}
          label={label}
          inputFormat={inputFormat}
          {...other}
          {...(onChange && { onChange })}
          renderInput={(params) => (
            <TextField
              {...textFieldProps}
              {...params}
              inputProps={{
                ...params.inputProps,
                placeholder: textPlaceholder,
                readOnly: textFieldProps?.disabled,
              }}
              fullWidth
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
      )}
    />
  );
}
