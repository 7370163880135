import { ReactNode } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import {
  LinkProps,
  ListItemText,
  ListItemButton,
  ListItemIcon,
  ListItemButtonProps,
} from '@mui/material';
// config
import { ICON, NAVBAR } from '../../../config';

// ----------------------------------------------------------------------

const backgroundColorActiveNav = '#637381';
const textColorNav = '#F1F0EC';

// ----------------------------------------------------------------------

type IProps = LinkProps & ListItemButtonProps;

export interface ListItemStyleProps extends IProps {
  component?: ReactNode;
  to?: string;
  activeRoot?: boolean;
  activeSub?: boolean;
  subItem?: boolean;
  roles?: string[];
}

export const ListItemStyle = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'activeRoot' && prop !== 'activeSub' && prop !== 'subItem',
})<ListItemStyleProps>(({ activeRoot, activeSub, subItem, theme }) => ({
  ...theme.typography.placeholder,
  position: 'relative',
  height: NAVBAR.DASHBOARD_ITEM_ROOT_HEIGHT,
  textTransform: 'capitalize',
  padding: '10px',
  color: textColorNav,
  borderRadius: 3,
  marginBottom: '10px',
  // '&:hover': { fontWeight: 500, backgroundColor: backgroundColorActiveNav, opacity: 0.8 },
  // activeRoot
  ...(activeRoot && {
    ...theme.typography.placeholder,
    fontWeight: 500,
    color: textColorNav,
    backgroundColor: backgroundColorActiveNav,
    '&:hover': { fontWeight: 500, backgroundColor: backgroundColorActiveNav },
  }),
  // activeSub
  ...(activeSub && {
    ...theme.typography.placeholder,
    color: theme.palette.text.primary,
  }),
  // subItem
  ...(subItem && {
    height: NAVBAR.DASHBOARD_ITEM_SUB_HEIGHT,
  }),
}));

interface ListItemTextStyleProps extends ListItemButtonProps {
  isCollapse?: boolean;
}

export const ListItemTextStyle = styled(ListItemText, {
  shouldForwardProp: (prop) => prop !== 'isCollapse',
})<ListItemTextStyleProps>(({ isCollapse, theme }) => ({
  whiteSpace: 'nowrap',
  transition: theme.transitions.create(['width', 'opacity'], {
    duration: theme.transitions.duration.shorter,
  }),
  ...(isCollapse && {
    width: 0,
    opacity: 0,
  }),
}));

export const ListItemIconStyle = styled(ListItemIcon)({
  width: ICON.NAVBAR_ITEM,
  height: ICON.NAVBAR_ITEM,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& svg': { width: '100%', height: '100%' },
});

export const ListItemIconRightStyle = styled(ListItemIcon)({
  marginLeft: '10px',
  width: 7,
  height: 7,
  display: 'block',
  color: '#C63E2E',
  '& svg': { width: '100%', height: '100%' },
});

export const ListItemTextIconRightStyle = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
}));
